import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Table,
  Select,
  TreeSelect,
} from 'antd';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';

import MaterialTableAddDrawer from './MaterialTableAddDrawer';
import BorderlessButton from '../../../common/buttons/BorderlessButton';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import OnTraccrNumberInput from '../../../common/inputs/OnTraccrNumberInput';
import DisplayText from '../../../common/text/DisplayText';
import ManualEntryDatePicker from '../../../clock/ManualEntry/ManualEntryDatePicker';

import { getPriceFloat, hasMarkup } from '../../../materials/materialsHelpers';
import { getIdMap, isNullOrUndefined } from '../../../helpers/helpers';
import { generateId } from '../../formHelpers';
import { floatIsBad } from '../../ResponderHelpers';
import useCheckTableMaxRows from '../../../common/hooks/useCheckTableMaxRows';
import { updateStackedDates, getCalculationTableColumn } from './formFieldsHelpers';
import {
  getColumnTitle, getCostColumn, getPriceColumn, getUpdatedDateColumn,
} from './FormFieldColumns';
import { currencyFormatter } from '../../../helpers/inputParsers';
import usePreloadHook from '../../../common/hooks/usePreloadHook';

/*
  name: 'Name',
  description: 'Description',
  partNumber: 'Part Number',
  units: 'Units',
  price: 'Price',
  quantity: 'Quantity',
  total: 'Total',

*/
const defaultRender = ({
  onValueChanged,
  key,
  isDisplay,
  textarea,
}) => function render(val, record) {
  if (isDisplay) return val;
  return (
    <OnTraccrTextInput
      textarea={textarea}
      value={val}
      onChange={(e) => {
        const {
          target: {
            value,
          } = {},
        } = e;
        onValueChanged(record.id, { [key]: value });
      }}
    />
  );
};

// eslint-disable-next-line react/function-component-definition
const totalRender = (isDisplay) => (total) => {
  // eslint-disable-next-line react/destructuring-assignment
  const totalText = total ? currencyFormatter(total) : '$0.00';
  if (isDisplay) return totalText;
  return (
    <Row align="middle">
      <DisplayText title={totalText} style={{ marginBottom: 0 }} />
    </Row>
  );
};

const getMaterialColumnMap = ({
  onQuantityChange,
  isDisplay,
  discount,
  onValueChanged,
  equipmentIdMap,
  globalMaterialLocationsMap,
  globalMaterialLocations,
  requiredColumns,
  materials,
  preventEdits,
  columnKeyMap,
  isAssemblyMaterial = false,
}) => {
  const onUpdatedDateChange = (value, record) => {
    onValueChanged(record.id, { updatedDate: value.join(', ') });
  };

  const isReadOnly = isDisplay || preventEdits || isAssemblyMaterial;

  const updatedDateColumn = getUpdatedDateColumn({
    requiredColumns,
    onChange: onUpdatedDateChange,
    isDisplay: isReadOnly,
  });

  const materialCostColumn = getCostColumn({
    title: 'Material Cost',
    dataIndex: 'cost',
    requiredColumns,
    onChange: (value, record) => onValueChanged(record.id, { cost: value }),
    isDisplay: isReadOnly,
  });

  const labourCostColumn = getCostColumn({
    title: 'Labour Cost',
    dataIndex: 'labourCost',
    requiredColumns,
    onChange: (value, record) => onValueChanged(record.id, { labourCost: value }),
    isDisplay: isReadOnly,
  });

  const materialPriceColumn = getPriceColumn({
    title: 'Material Price',
    dataIndex: 'price',
    requiredColumns,
    onChange: (value, record) => onValueChanged(record.id, { price: value }),
    isDisplay: isReadOnly,
    discount,
  });

  const labourPriceColumn = getPriceColumn({
    title: 'Labour Price',
    dataIndex: 'labourPrice',
    requiredColumns,
    onChange: (value, record) => onValueChanged(record.id, { labourPrice: value }),
    isDisplay: isReadOnly,
    discount,
  });

  const columns = {
    name: {
      title: getColumnTitle({ requiredColumns, title: 'Name' }),
      dataIndex: 'name',
      width: 100,
      render: defaultRender({
        onValueChanged, key: 'name', isDisplay: isReadOnly, textarea: true,
      }),
    },
    description: {
      title: getColumnTitle({ requiredColumns, title: 'Description' }),
      dataIndex: 'description',
      width: 100,
      render: defaultRender({
        onValueChanged, key: 'description', isDisplay: isReadOnly, textarea: true,
      }),
    },
    partNumber: {
      title: getColumnTitle({ requiredColumns, title: 'Part Number' }),
      dataIndex: 'partNumber',
      width: 100,
      render: defaultRender({ onValueChanged, key: 'partNumber', isDisplay: isReadOnly }),
    },
    units: {
      title: getColumnTitle({ requiredColumns, title: 'Units' }),
      dataIndex: 'units',
      width: 100,
      render: defaultRender({ onValueChanged, key: 'units', isDisplay: isReadOnly }),
    },
    cost: materialCostColumn,
    labourCost: labourCostColumn,
    price: materialPriceColumn,
    labourPrice: labourPriceColumn,
    quantity: {
      title: getColumnTitle({ requiredColumns, title: 'Quantity' }),
      dataIndex: 'quantity',
      width: 100,
      render: (quantity, record) => {
        if (isDisplay || isAssemblyMaterial) return quantity;
        return (
          <Row align="middle">
            <OnTraccrNumberInput
              min={0}
              precision={2}
              value={quantity}
              onChange={(value) => onQuantityChange({ id: record.id, value })}
            />
          </Row>
        );
      },
    },
    currentQuantity: {
      title: 'Current Quantity',
      dataIndex: 'currentQuantity',
      width: 100,
      render: (currentQuantity) => currentQuantity,
    },
    quantityAllocated: {
      title: 'Quantity Allocated',
      dataIndex: 'quantityAllocated',
      width: 100,
      render: (quantityAllocated, record) => {
        const { quantityAllocated: { isReadOnly: qtyIsReadOnly = false } } = columnKeyMap;
        if (isReadOnly || qtyIsReadOnly || record.isAssembly) {
          return quantityAllocated;
        }
        return (
          <OnTraccrNumberInput
            key={record.id}
            min={0}
            precision={2}
            value={quantityAllocated}
            defaultValue={quantityAllocated}
            onChange={(newQuantityAllocated) => (
              onValueChanged(record.id, { quantityAllocated: newQuantityAllocated })
            )}
          />
        );
      },
    },
    total: {
      title: 'Total Material Cost',
      dataIndex: 'total',
      width: 100,
      render: totalRender(isDisplay || isAssemblyMaterial),
    },
    labourCostTotal: {
      title: 'Total Labour Cost',
      dataIndex: 'labourCostTotal',
      width: 100,
      render: totalRender(isDisplay || isAssemblyMaterial),
    },
    labourAndMaterialTotal: {
      title: 'Material Total',
      dataIndex: 'labourAndMaterialTotal',
      width: 100,
      render: totalRender(isDisplay || isAssemblyMaterial),
    },
    location: {
      title: getColumnTitle({ requiredColumns, title: 'Location' }),
      dataIndex: 'locationId',
      width: 250,
      render: (locationId, record) => {
        if (isDisplay) return record.locationText;
        let { locations } = record;
        if (!locations) {
          /*
            Locations is not stored with the submitted record
            so record.locations is empty on edit
            Pull from material list
          */
          locations = materials[record.materialId]?.locations ?? [];
        }
        const opts = locations.map((loc) => {
          const { id: locId, equipmentId, globalMaterialLocationsId } = loc;
          const {
            [equipmentId]: { name: equipmentName } = {},
          } = equipmentIdMap;
          const {
            [globalMaterialLocationsId]: { locationText: globalMaterialLocationsText } = {},
          } = globalMaterialLocationsMap;
          return { label: equipmentName || globalMaterialLocationsText, value: locId };
        });
        if (opts.length === 1) {
          const [opt] = opts;
          if (!locationId) {
            onValueChanged(record.id, { locationId: opt.value, locationText: opt.label });
          }
          return opt.label;
        }

        return (
          <Select
            options={opts}
            optionFilterProp="label"
            style={{ width: 250 }}
            value={record.locationId}
            onChange={(_, opt) => {
              onValueChanged(record.id, { locationId: opt.value, locationText: opt.label });
            }}
          />
        );
      },
    },
    debitOrCredit: {
      title: getColumnTitle({ requiredColumns, title: 'Debit/Credit' }),
      dataIndex: 'debitOrCredit',
      width: 150,
      render: (debitOrCredit, record) => {
        if (isDisplay) return debitOrCredit;
        const opts = [
          { label: 'Debit', value: 'Debit' },
          { label: 'Credit', value: 'Credit' },
        ];
        return (
          <Select
            options={opts}
            optionFilterProp="label"
            style={{ width: 150 }}
            value={debitOrCredit}
            onChange={(_, opt) => {
              onValueChanged(record.id, { debitOrCredit: opt.value });
            }}
          />
        );
      },
    },
    toLocation: {
      title: getColumnTitle({ requiredColumns, title: 'To Location' }),
      dataIndex: 'toLocation',
      width: 250,
      render: (toLocation, record) => {
        if (isDisplay || isAssemblyMaterial) return record.toLocationText;
        let { locations: existingLocations } = record;
        if (!existingLocations) {
          existingLocations = materials[record.materialId]?.locations ?? [];
        }
        const existingOpts = existingLocations.map((loc) => {
          const { id: locId, equipmentId, globalMaterialLocationsId } = loc;
          const {
            [equipmentId]: { name: equipmentName } = {},
          } = equipmentIdMap;
          const {
            [globalMaterialLocationsId]: { locationText: globalMaterialLocationsText } = {},
          } = globalMaterialLocationsMap;
          return {
            label: equipmentName || globalMaterialLocationsText,
            value: locId,
            isLeaf: true,
          };
        });
        const existingOptsMap = getIdMap(existingOpts, 'label');
        const globalOpts = globalMaterialLocations.map((loc) => {
          const { id: locId, locationText } = loc;
          return { label: locationText, value: locId, isLeaf: true };
        }).filter((loc) => !existingOptsMap[loc.label]);

        const treeData = [];

        if (existingOpts.length) {
          treeData.push({
            value: 'assignedLocations',
            title: 'Assigned Locations',
            children: existingOpts,
            selectable: false,
          });
        }

        if (globalOpts.length) {
          treeData.push({
            value: 'globalLocations',
            title: 'Global Locations',
            children: globalOpts,
            selectable: false,
          });
        }

        return (
          <TreeSelect
            showSearch
            treeDefaultExpandAll
            treeNodeFilterProp="label"
            treeData={treeData}
            style={{ width: 250 }}
            value={record.toLocation}
            onSelect={(_, opt) => {
              onValueChanged(record.id, {
                toLocation: opt.value,
                toLocationText: opt.label,
                isGlobalToLocation: !!globalMaterialLocationsMap[opt.value],
              });
            }}
          />
        );
      },
    },
    addedDate: {
      title: getColumnTitle({ requiredColumns, title: 'Date Added' }),
      dataIndex: 'addedDate',
      width: 250,
      render: (date, record) => {
        if (isReadOnly) return date;

        return (
          <ManualEntryDatePicker
            value={date}
            onChange={(newDate) => onValueChanged(record.id, { addedDate: newDate })}
          />
        );
      },
    },
    updatedDate: updatedDateColumn,
    notes: {
      title: getColumnTitle({ requiredColumns, title: 'Notes' }),
      dataIndex: 'notes',
      width: 250,
      render: (notes, record) => {
        if (isReadOnly) return notes;
        return (
          <OnTraccrTextInput
            key={record.id}
            value={notes}
            onChange={(e) => {
              const {
                target: {
                  value,
                } = {},
              } = e;
              onValueChanged(record.id, { notes: value });
            }}
            textarea
          />
        );
      },
    },
    supplier: {
      title: getColumnTitle({ requiredColumns, title: 'Supplier' }),
      dataIndex: 'supplier',
      width: 100,
      render: defaultRender({ onValueChanged, key: 'supplier', isDisplay: isReadOnly }),
    },
  };

  return columns;
};

const materialColumns = ({
  onDelete,
  onQuantityChange,
  onValueChanged,
  columns,
  columnKeyMap,
  isDisplay,
  discount,
  equipmentIdMap,
  globalMaterialLocationsMap,
  globalMaterialLocations,
  requiredColumns,
  materials,
  preventEdits,
  isAssemblyMaterial = false,
}) => {
  const cols = [];
  const materialColumnMap = getMaterialColumnMap({
    onQuantityChange,
    isDisplay,
    discount,
    onValueChanged,
    equipmentIdMap,
    globalMaterialLocationsMap,
    globalMaterialLocations,
    requiredColumns,
    materials,
    preventEdits,
    columnKeyMap,
    isAssemblyMaterial,
  });
  columns.forEach((col) => {
    if (col.key in materialColumnMap) {
      cols.push(materialColumnMap[col.key]);
    } else if (col.isCalculation) {
      cols.push(getCalculationTableColumn(col, {
        width: 100,
        isDisplay,
      }));
    }
  });
  if (!isDisplay && !isAssemblyMaterial) {
    cols.push({
      title: '',
      dataIndex: '',
      width: 100,
      render: (_, record) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: 'red' }} />}
          onClick={() => onDelete(record.id)}
        />
      ),
    });
  }
  return cols;
};

const decorateMaterial = (material = {}, discount = 0) => {
  const newMaterial = { ...material };
  const {
    userPrice,
    userLabourPrice,
    debitOrCredit,
    labourCost,
    locationId,
    locations = [],
    quantity,
    isPreloaded,
  } = material;

  if (!debitOrCredit) newMaterial.debitOrCredit = 'Debit';

  const badPrice = floatIsBad(userPrice);
  const badLabourPrice = floatIsBad(userLabourPrice);
  const hasUserPrice = !isNullOrUndefined(userPrice);
  const hasUserLabourPrice = !isNullOrUndefined(userLabourPrice);
  const costNumber = Number(material.cost);
  const labourCostNumber = Number(labourCost);

  if (
    (hasUserPrice && !badPrice)
    || (!hasUserPrice && !Number.isNaN(costNumber) && material.cost && hasMarkup(material.markup))
  ) {
    const price = hasUserPrice
      ? userPrice
      : getPriceFloat(
        material.cost,
        material.markup * 100,
        discount,
      ); // Takes markup as percentage

    const floatPrice = parseFloat(price);

    newMaterial.price = floatPrice;
    newMaterial.total = floatPrice * (quantity ?? 0);
  } else {
    newMaterial.total = 0;
  }

  if (
    (hasUserLabourPrice && !badLabourPrice)
    || (!hasUserLabourPrice
        && !Number.isNaN(labourCostNumber)
        && labourCost && hasMarkup(material.labourMarkup))
  ) {
    const labourPrice = hasUserLabourPrice
      ? userLabourPrice
      : getPriceFloat(
        labourCost,
        material.labourMarkup * 100,
        discount,
      ); // Takes markup as percentage

    const floatLabourPrice = parseFloat(labourPrice);

    newMaterial.labourPrice = floatLabourPrice;
    newMaterial.labourCostTotal = floatLabourPrice * (quantity ?? 0);
  } else {
    newMaterial.labourCostTotal = 0;
  }

  newMaterial.labourAndMaterialTotal = newMaterial.total + newMaterial.labourCostTotal;

  if (!isPreloaded) {
    // populate current quantity
    if (!locationId) {
      const totalQuantity = locations?.reduce((acc, loc) => (
        acc + ((loc?.quantity ?? 0) - (loc?.quantityAllocated ?? 0))
      ), 0);
      newMaterial.currentQuantity = totalQuantity;
    } else {
      const ourLocation = locations?.find((loc) => loc.id === locationId);
      newMaterial.currentQuantity = (ourLocation?.quantity ?? 0)
        - (ourLocation?.quantityAllocated ?? 0);
    }
  }

  return newMaterial;
};

export default function MaterialTablePreview({
  materials = {},
  columns = [],
  previewProps = {},
  setPreviewProps,
  isDisplay,
  id,
  setResponses,
  responses = {},
  responding = false,
  projectId,
  customerId,
  configProps,
  showCondensedView,
}) {
  const {
    hideAddNewButton,
    requiredColumns,
    preventEdits,
  } = configProps ?? {};
  const values = previewProps.values || []; // For Responses
  const {
    selected: previewSelected = [],
  } = previewProps;
  const {
    [id]: {
      values: responseSelected = [],
    } = {},
  } = responses;

  const rawSelected = responding ? responseSelected : previewSelected;

  const selected = useMemo(() => {
    const newId = generateId();
    return rawSelected?.map((s, idx) => (
      // Backwards Compatability - HARBOUR-4193
      s.materialId ? s : { ...s, materialId: s.id, id: newId + idx }
    ));
  }, [rawSelected]);

  const [showAdd, setShowAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const customers = useSelector((state) => state.customers.customers);
  const projects = useSelector((state) => state.projects.projects);
  const equipment = useSelector((state) => state.equipment.equipment);
  const globalMaterialLocations = useSelector(
    (state) => state.globalMaterialLocations.globalMaterialLocations,
  );

  const columnKeyMap = useMemo(() => getIdMap(columns, 'key'), [columns]);
  const equipmentIdMap = useMemo(() => getIdMap(equipment), [equipment]);
  const globalMaterialLocationsMap = useMemo(() => (
    getIdMap(globalMaterialLocations)
  ), [globalMaterialLocations]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);

  const discount = useMemo(() => {
    const {
      [projectId]: {
        materialDiscount: projectMaterialDiscount,
        customerId: projectCustomerId = customerId,
      } = {},
    } = projectMap;
    const {
      [projectCustomerId]: {
        materialDiscount: customerMaterialDiscount = 0,
      } = {},
    } = customers;
    return projectMaterialDiscount || customerMaterialDiscount;
  }, [projectMap, projectId, customerId, customers]);

  const decoratedSelected = useMemo(() => (
    selected.map((mat) => decorateMaterial(mat, discount))
  ), [selected, discount]);

  useEffect(() => {
    // If the user change the customer or project it will change
    // discount and therefore the price/total
    // We need to push that back to the responses.
    if (!responding) return;
    const shouldUpdate = decoratedSelected.some((decorated, i) => {
      const selectedMat = selected[i];
      return decorated.price !== selectedMat.price || decorated.total !== selectedMat.total;
    });
    if (!shouldUpdate) return;
    setResponses({
      ...responses,
      [id]: {
        ...(responses[id]),
        values: decoratedSelected,
        columns,
      },
    });
  }, [decoratedSelected, selected, responding]);

  const showAddDrawer = useCallback(() => setShowAdd(true), []);
  const closeAddDrawer = useCallback(() => setShowAdd(false), []);

  const updateSelected = useCallback((newSelected) => {
    if (responding) {
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          values: newSelected,
          columns,
        },
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
  }, [
    responding,
    responses,
    id,
    columns,
    previewProps,
    setResponses,
    setPreviewProps,
  ]);

  const onAddNew = useCallback(() => {
    const newSelected = selected.concat([{
      id: generateId(),
      materialId: generateId(),
      isCustom: true,
      addedDate: TaskHelpers.formatDate(DateTime.local().toMillis()),
      updatedDate: DateTime.local().toISO(),
    }]);
    updateSelected(newSelected);
  }, [selected, updateSelected]);

  const onSelect = useCallback((materialIds) => {
    const newId = generateId();
    const newMaterials = materialIds
      .map((materialId, idx) => {
        const relevantMaterial = materials[materialId];
        const relevantAssemblyMaterials = [];

        relevantMaterial?.assemblyMaterials.forEach((assemblyMaterial) => {
          const { assemblyId, materialId: assemblyMaterialId, quantity = 0 } = assemblyMaterial;
          if (materials[assemblyMaterialId]) {
            relevantAssemblyMaterials.push(decorateMaterial({
              ...materials[assemblyMaterialId],
              id: `${assemblyId}.${assemblyMaterialId}`,
              quantity,
              originalQuantity: quantity,
            }));
          }
        });

        const newMaterial = {
          ...relevantMaterial,
          quantityAllocated: null, // [2024-10-04] Should not map from material as of right now
          materialId,
          id: newId + idx,
          addedDate: TaskHelpers.formatDate(DateTime.local().toMillis()),
          updatedDate: DateTime.local().toISO(),
          assemblyMaterials: relevantAssemblyMaterials,
        };
        delete newMaterial.quantity;
        return decorateMaterial(newMaterial, discount);
      });

    const newSelected = decoratedSelected.concat(newMaterials);
    updateSelected(newSelected);
    setShowAdd(false);
  }, [decoratedSelected, materials, discount, updateSelected]);

  const onDelete = useCallback((rowId) => {
    const newSelected = decoratedSelected.filter((material) => material.id !== rowId);
    updateSelected(newSelected);
  }, [decoratedSelected, updateSelected]);

  const calculateNewAssemblyValues = (relevantMaterial, newQuantity) => {
    if (!relevantMaterial.isAssembly) return relevantMaterial;

    const newMaterial = { ...relevantMaterial };

    const {
      assemblyMaterials = [],
    } = newMaterial;
    const newAssemblyMaterials = assemblyMaterials.map((assemblyMaterial) => (
      decorateMaterial({
        ...assemblyMaterial,
        quantity: newQuantity * (assemblyMaterial.originalQuantity || 0),
      })
    ));

    newMaterial.assemblyMaterials = newAssemblyMaterials;
    return newMaterial;
  };

  const onQuantityChange = useCallback(({ id: rowId, value }) => {
    const newSelected = decoratedSelected.map((material) => {
      if (material.id !== rowId) return material;
      return decorateMaterial({
        ...calculateNewAssemblyValues(material, value),
        quantity: value,
        updatedDate: updateStackedDates(material.updatedDate),
      }, discount);
    });
    updateSelected(newSelected);
  }, [discount, decoratedSelected, updateSelected]);

  const onValueChanged = useCallback((rowId, newData = {}) => {
    const updateMaterial = (material) => {
      if (material.id !== rowId) return material;
      const formattedData = { ...newData };

      if ('price' in newData) {
        formattedData.userPrice = newData.price;
      }

      if ('labourPrice' in newData) {
        formattedData.userLabourPrice = newData.labourPrice;
      }

      if (!('updatedDate' in newData)) {
        formattedData.updatedDate = updateStackedDates(material.updatedDate);
      }

      if ('debitOrCredit' in newData && material.assemblyMaterials?.length) {
        formattedData.assemblyMaterials = material.assemblyMaterials.map((assemblyMaterial) => ({
          ...assemblyMaterial,
          debitOrCredit: newData.debitOrCredit || 'Debit',
        }));
      }

      return decorateMaterial({
        ...material,
        ...formattedData,
      }, discount);
    };

    const newSelected = decoratedSelected.map((material) => {
      const updatedMaterial = updateMaterial(material);
      updatedMaterial.assemblyMaterials = updatedMaterial.assemblyMaterials?.map((assemblyMaterial) => (
        updateMaterial(assemblyMaterial)
      ));
      return updatedMaterial;
    });
    updateSelected(newSelected);
  }, [discount, decoratedSelected, updateSelected, materials]);

  const getTableColumns = useCallback((isAssemblyMaterial = false) => (
    materialColumns({
      onDelete,
      onQuantityChange,
      onValueChanged,
      columns,
      columnKeyMap,
      isDisplay,
      discount,
      equipmentIdMap,
      globalMaterialLocationsMap,
      globalMaterialLocations,
      requiredColumns,
      materials,
      preventEdits,
      isAssemblyMaterial,
    })
  ), [
    columns,
    columnKeyMap,
    isDisplay,
    onDelete,
    onQuantityChange,
    onValueChanged,
    discount,
    equipmentIdMap,
    globalMaterialLocationsMap,
    globalMaterialLocations,
    requiredColumns,
    materials,
    preventEdits,
  ]);

  const dataSource = useMemo(() => (
    isDisplay && !responding ? values : decoratedSelected
  ), [isDisplay, responding, values, decoratedSelected]);

  const {
    maxExistingAllowed,
    shouldAddButtonBeEnabled = true,
  } = useCheckTableMaxRows({
    configProps,
    currentRowsLength: dataSource?.length,
  });

  const rowExpandable = useCallback((record) => !!record.isAssembly, []);
  const onExpand = useCallback((event, { id }) => {
    if (!event) {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== id));
      return;
    }

    setExpandedRowKeys([...expandedRowKeys, id]);
  }, [expandedRowKeys]);

  const expandedRowRender = useCallback((record) => (
    <Table
      columns={getTableColumns(true)}
      dataSource={record?.assemblyMaterials || []}
      pagination={false}
      size="small"
      bordered
      key="id"
    />
  ), [materials, getTableColumns]);

  const expandable = useMemo(() => ({
    expandedRowKeys,
    onExpand,
    rowExpandable,
    expandedRowRender,
  }), [expandedRowKeys, onExpand, rowExpandable, expandedRowRender]);

  usePreloadHook({
    responses,
    id,
    setResponses,
    columns,
    selected,
    responding,
    configProps,
    type: 'Materials',
    setLoading,
  })

  return (
    <>
      <Row style={{ marginTop: showCondensedView ? 0 : 15 }}>
        {!isDisplay && !loading && (
          <Row align="middle" justify="space-between" style={{ width: '100%', marginBottom: 10 }}>
            <Col>
              <Row gutter={20}>
                <Col>
                  <OnTraccrButton
                    title="Add Material"
                    onClick={showAddDrawer}
                    icon={<PlusOutlined />}
                    disabled={!shouldAddButtonBeEnabled}
                  />
                </Col>
                { !hideAddNewButton && (
                  <Col>
                    <OnTraccrButton
                      title="Add New"
                      onClick={onAddNew}
                      icon={<PlusOutlined />}
                      disabled={!shouldAddButtonBeEnabled}
                    />
                  </Col>
                )}
              </Row>
            </Col>

            {discount ? (
              <Col>
                <Row justify="end" align="middle" style={{ color: 'red' }}>
                  <ExclamationCircleOutlined style={{ color: 'red', height: 14, marginRight: 10 }} />
                  {`Applying ${discount * 100}% discount to material prices that have not been changed`}
                </Row>
              </Col>
            ) : null}
          </Row>
        )}
        { !showCondensedView || dataSource?.length ? (
          <Table
            style={{ width: '100%', overflow: 'auto' }}
            columns={getTableColumns()}
            size="small"
            pagination={false}
            dataSource={dataSource}
            expandable={expandable}
            rowKey="id"
          />
        ) : (
          <DisplayText title="No Materials Selected" style={{ marginBottom: 0 }} />
        )}
      </Row>
      <MaterialTableAddDrawer
        visible={showAdd}
        onClose={closeAddDrawer}
        onSelect={onSelect}
        onSubmit={onSelect}
        materials={materials}
        maxExistingAllowed={maxExistingAllowed}
      />
    </>
  );
}

MaterialTablePreview.propTypes = {
  materials: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    equipmentId: PropTypes.string,
    locationId: PropTypes.string,
  })),
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    dataIndex: PropTypes.string,
    key: PropTypes.string,
    editable: PropTypes.bool,
    required: PropTypes.bool,
    type: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
  })),
  previewProps: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOf([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
      price: PropTypes.number,
      quantity: PropTypes.number,
      equipmentId: PropTypes.string,
      locationId: PropTypes.string,
    })),
    selected: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      quantity: PropTypes.number,
      equipmentId: PropTypes.string,
      locationId: PropTypes.string,
    })),
  }),
  setPreviewProps: PropTypes.func,
  isDisplay: PropTypes.bool,
  id: PropTypes.string,
  setResponses: PropTypes.func,
  responses: PropTypes.objectOf(PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      quantity: PropTypes.number,
      equipmentId: PropTypes.string,
      locationId: PropTypes.string,
    })),
    selected: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      quantity: PropTypes.number,
      equipmentId: PropTypes.string,
      locationId: PropTypes.string,
    })),
    columns: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      editable: PropTypes.bool,
      required: PropTypes.bool,
      type: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })),
    })),
  })),
  responding: PropTypes.bool,
  projectId: PropTypes.string,
  customerId: PropTypes.string,
  configProps: PropTypes.shape({
    hideAddNewButton: PropTypes.bool,
    requiredColumns: PropTypes.bool,
  }),
  showCondensedView: PropTypes.bool,
};

MaterialTablePreview.defaultProps = {
  materials: {},
  columns: [],
  previewProps: {},
  setPreviewProps: null,
  isDisplay: false,
  id: null,
  setResponses: null,
  responses: {},
  responding: false,
  projectId: null,
  customerId: null,
  configProps: {},
  showCondensedView: false,
};
