import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import BreadcrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import OnTraccrEmpty from '../common/OnTraccrEmpty';

import BoardHeader from './BoardHeader';
import BoardAddDrawer from './BoardAddDrawer';
import BoardView from './BoardCardView/BoardView';
import BoardListView from './BoardListView/BoardListView';
import BoardCardDrawer from './BoardCardDrawer';
import LargeBoard from './LargeBoard/LargeBoard';

import Permissions from '../auth/Permissions';

import {
  getBoards,
  getBoardCardTemplates,
  reorderCards,
  getBoardDetails,
  openCardDrawer,
  closeFormDrawer,
  getFormMappings,
  getBoardTemplates,
} from './state/boards.actions';
import { getUserLabels, getUsers } from '../users/state/users.actions';
import { getMaterials } from '../materials/state/materials.actions';
import { getCustomerLabels, getCustomers } from '../contacts/customers/state/customers.actions';
import { getEquipment } from '../equipment/state/equipment.actions';
import {
  getClientPortalAccounts,
  getClientPortalSettings,
} from '../clientPortal/state/clientPortal.actions';
import { getTemplates, getCustomTables, getAssignedFormTemplates } from '../forms/state/forms.actions';
import { getSavedAnalyticsReports } from '../analytics/state/analytics.actions';
import { getSavedDashboards } from '../dashboard/state/dashboard.actions';
import { getCustomFields } from '../timecards/state/timecards.actions';
import { getProjectCustomFieldTemplate } from '../projects/state/projects.actions';

import BoardCardFormMappingDrawer from './BoardCardForms/BoardCardFormMappingDrawer';
import BoardSelector from './BoardSelector';

import FormResponder from '../forms/FormResponder';

import { BOARD_CARD_ANALYTICS_TYPE } from '../analytics/analytics.constants';
import BoardTimelineView from './BoardTimelineView/BoardTimelineView';
import { VIEW_TYPES } from './boards.constants';
import useToggle from '../common/hooks/useToggle';
import useBoardPermissions from '../common/hooks/useBoardPermissions';
import useFilteredBoardList from '../common/hooks/useFilteredBoardList';

const CRUMBS = [{
  text: 'Boards',
  icon: 'group',
}];

const STORAGE_KEY = 'selectedBoardId';
export default function Boards() {
  const dispatch = useDispatch();
  const {
    pathname,
    state: {
      boardId: locationBoardId,
      cardId: locationCardId,
    } = {},
  } = useLocation();
  const history = useHistory();

  const boards = useSelector((state) => state.boards.boards);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const boardTags = useSelector((state) => state.boards.myCardTags);
  const viewType = useSelector((state) => state.boards.viewType);
  const sortType = useSelector((state) => state.boards.sortType.type);
  const menuCollapsed = useSelector((state) => state.main.menuCollapsed);
  const latestManualDraftId = useSelector((state) => state.forms.latestManualDraftId);
  const {
    visible: formDrawerVisible = false,
    assignedForm,
    formData,
    cardId: formCardId,
    isEdit: selectedFormIsEdit,
    fileMap,
    ids: preloadedIds,
  } = useSelector((state) => state.boards.formDrawerConfig);
  const {
    visible: formMapDrawerVisible = false,
    selectedCardId: formMapCardId,
  } = useSelector((state) => state.boards.formMapDrawerConfig);
  const {
    users = [],
    cards = [],
  } = useSelector((state) => state.boards.selectedBoard || {});

  const [addDrawerVisible, setAddDrawerVisible] = useState(false);
  const [selectedBoardId, setSelectedBoardId] = useState();

  const {
    isToggled: loading,
    toggle: toggleLoading,
  } = useToggle();

  const fullSelectedBoard = useMemo(
    () => boards[selectedBoardId] || {},
    [boards, selectedBoardId],
  );

  const assignedDraft = useMemo(() => {
    const relevantDraftId = latestManualDraftId ?? assignedForm?.draftId;
    if (!assignedForm) return { draftId: relevantDraftId };
    return {
      ...assignedForm,
      draftId: relevantDraftId,
    };
  }, [assignedForm, latestManualDraftId]);

  const {
    creatorId,
  } = fullSelectedBoard;

  const {
    [fullSelectedBoard.cardTypeId]:
    cardType,
  } = useSelector((state) => state.boards.cardTemplates);

  const largeBoardMode = cards.length > 2500;

  const userBoardPermissions = useBoardPermissions({ users, creatorId });

  const { canCreate } = userBoardPermissions;

  const showAddDrawer = useCallback(() => setAddDrawerVisible(true), []);
  const hideAddDrawer = useCallback(() => setAddDrawerVisible(false), []);
  const hideFormDrawer = useCallback(() => dispatch(closeFormDrawer()), [dispatch]);

  useEffect(() => {
    dispatch(getBoards());
    dispatch(getBoardTemplates());
    dispatch(getBoardCardTemplates());
    dispatch(getUserLabels());
    dispatch(getUsers());
    dispatch(getCustomers());
    dispatch(getCustomerLabels());
    dispatch(getMaterials());
    dispatch(getEquipment());
    dispatch(getClientPortalAccounts());
    dispatch(getClientPortalSettings());
    dispatch(getTemplates());
    dispatch(getCustomTables());
    dispatch(getSavedAnalyticsReports(BOARD_CARD_ANALYTICS_TYPE));
    dispatch(getSavedDashboards(BOARD_CARD_ANALYTICS_TYPE));
    dispatch(getAssignedFormTemplates());
    dispatch(getCustomFields());
    dispatch(getProjectCustomFieldTemplate());
    return () => {
      dispatch(reorderCards({
        type: 'default',
      }));
    };
  }, [dispatch]);

  useEffect(() => {
    const loadData = async () => {
      toggleLoading();
      await Promise.all([
        dispatch(getBoardDetails(selectedBoardId)),
        dispatch(getFormMappings(selectedBoardId)),
      ]);
      toggleLoading();
    };
    if (selectedBoardId) {
      loadData();
    }
  }, [selectedBoardId]);

  const tagsPerBoard = useMemo(() => {
    const tpbMap = {};
    boardTags.forEach((tag) => {
      const { boardId } = tag;
      const {
        [boardId]: oldCount = 0,
      } = tpbMap;
      tpbMap[boardId] = oldCount + 1;
    });
    return tpbMap;
  }, [boardTags]);

  const boardList = useFilteredBoardList();
  const noBoards = boardList.length === 0;

  useEffect(() => {
    const storageBoardId = window.localStorage.getItem(STORAGE_KEY);
    if (fullSelectedBoard && fullSelectedBoard.id) {
      if (
        fullSelectedBoard?.divisions?.some?.((divisionId) => (
          selectedDivisions.has(divisionId)
        ))) return;
      // Divisions have changed and board needs to change
      window.localStorage.removeItem(STORAGE_KEY);
      if (boardList.length === 0) {
        setSelectedBoardId();
      } else {
        setSelectedBoardId(boardList[0].id);
      }
      // Initial Load
    } else if (locationBoardId && locationBoardId in boards) {
      setSelectedBoardId(locationBoardId);
    } else if (storageBoardId && storageBoardId in boards) {
      setSelectedBoardId(storageBoardId);
    } else if (boardList.length > 0) {
      setSelectedBoardId(boardList[0].id);
    }
  }, [fullSelectedBoard, selectedDivisions, boardList, selectedBoardId, boards, locationBoardId]);

  useEffect(() => {
    if (selectedBoardId) window.localStorage.setItem(STORAGE_KEY, selectedBoardId);
  }, [selectedBoardId]);

  useEffect(() => {
    if (dispatch && locationCardId) {
      setTimeout(() => {
        dispatch(openCardDrawer({ selectedCardId: locationCardId }));
        history.replace(pathname);
      }, 250);
    }
  }, [dispatch, locationCardId, pathname, history]);

  const onBoardSelected = useCallback((boardId) => {
    setSelectedBoardId(boardId);
    if (sortType.startsWith('dateTime')) {
      dispatch(reorderCards({
        type: 'default',
      }));
    }
  }, [sortType]);

  const fullCrumbs = useMemo(() => {
    if (boardList.length === 0 || !fullSelectedBoard) {
      if (!Permissions.has('BOARDS_WRITE')) return CRUMBS;
      return [{
        text: (
          <span>
            Boards
            <span className="analytics-title-button" style={{ transform: 'translateY(-10px)' }}>
              <PlusOutlined onClick={showAddDrawer} style={{ fontSize: 20 }} />
            </span>
          </span>
        ),
        icon: 'group',
      }];
    }
    return CRUMBS.concat([{
      text: (
        <BoardSelector
          fullSelectedBoard={fullSelectedBoard}
          boardList={boardList}
          largeBoardMode={largeBoardMode}
          tagsPerBoard={tagsPerBoard}
          onBoardSelected={onBoardSelected}
          onAdd={showAddDrawer}
        />
      ),
    }]);
  }, [
    boardList,
    fullSelectedBoard,
    selectedDivisions,
    tagsPerBoard,
    largeBoardMode,
    onBoardSelected,
  ]);

  const body = useMemo(() => {
    if (noBoards || !fullSelectedBoard) return <OnTraccrEmpty />;
    if (largeBoardMode) return <LargeBoard />;
    // eslint-disable-next-line react/jsx-props-no-spreading
    switch (viewType) {
      case VIEW_TYPES.LIST_VIEW: {
        return (
          <BoardListView
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...fullSelectedBoard}
            useCardNumberAsCardTitle={cardType?.useCardNumberAsCardTitle}
            userBoardPermissions={userBoardPermissions}
            loading={loading}
            onBoardSelected={onBoardSelected}
          />
        );
      }
      case VIEW_TYPES.TIMELINE_VIEW: {
        return (
          <BoardTimelineView
            id={fullSelectedBoard?.id}
            cardTypeId={fullSelectedBoard?.cardTypeId}
            isLocked={fullSelectedBoard?.isLocked}
            disableCrossColumnSorting={fullSelectedBoard?.disableCrossColumnSorting}
            showCardNumber={fullSelectedBoard?.showCardNumber}
            useCardNumberAsCardTitle={cardType?.useCardNumberAsCardTitle}
            userBoardPermissions={userBoardPermissions}
            timelineDisplayFields={fullSelectedBoard?.timelineDisplayFields}
            onBoardSelected={onBoardSelected}
          />
        );
      }
      default: {
        return (
          <BoardView
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...fullSelectedBoard}
            useCardNumberAsCardTitle={cardType?.useCardNumberAsCardTitle}
            userBoardPermissions={userBoardPermissions}
            onBoardSelected={onBoardSelected}
          />
        );
      }
    }
  }, [
    noBoards,
    largeBoardMode,
    fullSelectedBoard,
    viewType,
    userBoardPermissions,
    loading,
    onBoardSelected,
  ]);

  return (
    <>
      <BreadcrumbContainer crumbs={fullCrumbs} fullscreen={menuCollapsed}>
        <BoardHeader
          selectedBoard={fullSelectedBoard}
          useCardNumberAsCardTitle={cardType?.useCardNumberAsCardTitle}
          onBoardSelect={onBoardSelected}
          largeBoardMode={largeBoardMode}
          canCreate={canCreate}
        />
        {body}
      </BreadcrumbContainer>
      <BoardAddDrawer
        visible={addDrawerVisible}
        onClose={hideAddDrawer}
      />
      <BoardCardDrawer
        boardId={selectedBoardId}
        cardTypeId={fullSelectedBoard.cardTypeId}
        divisions={fullSelectedBoard.divisions}
        showCardNumber={fullSelectedBoard.showCardNumber}
        useCardNumberAsCardTitle={cardType?.useCardNumberAsCardTitle}
        userBoardPermissions={userBoardPermissions}
        onBoardSelected={onBoardSelected}
      />
      <BoardCardFormMappingDrawer
        visible={formMapDrawerVisible}
        selectedCardId={formMapCardId}
        boardId={selectedBoardId}
        cardTypeId={fullSelectedBoard.cardTypeId}
        divisions={fullSelectedBoard.divisions}
      />
      <FormResponder
        visible={formDrawerVisible}
        onClose={hideFormDrawer}
        assignedForm={assignedDraft}
        preloadedData={formData}
        preloadedIds={preloadedIds}
        isOnEditStep={selectedFormIsEdit}
        cardId={formCardId}
        initialFileMap={fileMap}
      />
    </>
  );
}
