import { isNullOrUndefined } from '../../helpers/helpers';

export const getRowKey = ({
  projectId = null, rowId = null, templateId = null, formId = null,
}) => `${formId || projectId || templateId}.${rowId}`;

export const getRow = ({
  state,
  projectId,
  templateId,
  rowId,
  formId,
  isProgress,
}) => {
  const key = getRowKey({
    projectId,
    rowId,
    templateId,
    formId,
  });
  const stateKey = isProgress ? 'subContractUpdates' : 'scheduleOfValues';
  const {
    projects: {
      [stateKey]: {
        [key]: row = [],
      } = {},
    } = {},
  } = state;
  return row;
};

export const getHistoryEntry = (row) => {
  switch (row.updatedField) {
    case 'invoiceAmount':
      return {
        field: 'Invoice Amount',
        pastValue: row.lastInvoiceAmount,
        newValue: row.invoiceAmount,
        type: 'currency',
        diff: row.invoiceAmount - row.lastInvoiceAmount,
      };
    case 'description':
      return {
        field: 'Description',
        pastValue: row.lastDescription,
        newValue: row.description,
        type: 'text',
      };
    case 'percentageComplete':
      return {
        field: 'Percentage Complete',
        pastValue: row.lastPercentageComplete,
        newValue: row.percentageComplete,
        type: 'percent',
        diff: (row.percentageComplete - row.lastPercentageComplete).toFixed(2),
      };
    case 'contractAmount':
      return {
        field: 'Contract Amount',
        pastValue: row.lastContractAmount,
        newValue: row.contractAmount,
        type: 'currency',
        diff: row.contractAmount - row.lastContractAmount,
      };
    case 'notes':
      return {
        field: 'Notes',
        pastValue: row.lastNotes,
        newValue: row.notes,
        type: 'text',
      };
    case 'itemNumber':
      return {
        field: 'Item Number',
        pastValue: row.lastItemNumber,
        newValue: row.itemNumber,
        type: 'text',
      };
    case 'import':
      return {
        field: 'imported',
      };
    case 'number': {
      return {
        field: 'Number',
        pastValue: row.lastNumber,
        newValue: row.number,
        type: 'text',
      };
    }
    default:
      return {};
  }
};

export const isValueWithinBounds = (
  row,
  field,
  newValue,
  liveSummaryValues = [],
  isProgress = false,
) => {
  switch (field) {
    case 'totalContractAmount':
      return {
        valid: newValue >= row.currentContractValue,
        invalidMessage: 'Total Base Contract Value is less than the current contract value',
      };
    case 'invoiceAmount':
      if (!row.oldProgressToDate) {
        return {
          valid: row.progressToDate + newValue <= row.contractAmount,
          invalidMessage: 'Invoice Amount is greater than the remaining contract amount',
        };
      }

      return {
        valid: isNullOrUndefined(row.oldProgressToDate)
          ? row.progressToDate + newValue <= row.contractAmount
          : row.oldProgressToDate + newValue <= row.contractAmount,
        invalidMessage: 'Invoice Amount is greater than the remaining contract value',
      };
    case 'contractAmount':
      if (newValue < row.progressToDate) {
        return {
          valid: false,
          invalidMessage: 'Contract Amount is less than the progress to date',
        };
      }

      if (liveSummaryValues.length && !isProgress) {
        return {
          valid: (
            newValue + liveSummaryValues[0].currentContractValue - row.contractAmount
          ) <= liveSummaryValues[0].totalContractAmount,
          invalidMessage: 'Current base contract value will exceed the total base contract value',
        };
      }
      return {
        valid: true,
      };
    default:
      return {
        valid: true,
      };
  }
};

export const isInputDirty = (row, field, newValue) => {
  switch (field) {
    case 'totalContractAmount':
      return newValue !== row.totalContractAmount;
    case 'holdbackPercentage':
      return (newValue / 100).toFixed(2) !== row.holdbackPercentage.toFixed(2);
    case 'invoiceAmount':
      return newValue !== row.oldInvoiceAmount;
    case 'contractAmount':
      return newValue !== row.oldContractAmount;
    case 'percentageComplete':
      return newValue !== row.oldPercentageComplete;
    case 'description':
      return newValue !== row[field];
    case 'number':
      return newValue !== row[field];
    default:
      return false;
  }
};

export const getLatestRowValue = ({
  state,
  projectId,
  templateId,
  rowId,
  formId,
  isProgress,
}) => {
  const row = getRow({
    state,
    projectId,
    templateId,
    rowId,
    formId,
    isProgress,
  });
  return row[row.length - 1] || {};
};

const itemNumberSort = (a, b) => {
  if (a.itemNumber < b.itemNumber) {
    return -1;
  }
  if (a.itemNumber > b.itemNumber) {
    return 1;
  }
  return 0;
};

export const filterScheduleOfValues = (scheduleOfValues, filterTimestamp) => {
  const filteredScheduleOfValues = {};

  Object.keys(scheduleOfValues).forEach((row) => {
    const rowValues = scheduleOfValues[row];
    const filteredValues = [];
    rowValues.forEach((val) => {
      const { timestamp } = val;
      if (timestamp < filterTimestamp) {
        filteredValues.push({ ...val });
      }
    });
    filteredScheduleOfValues[row] = filteredValues;
  });

  return filteredScheduleOfValues;
};

export const scheduleOfValuesData = ({
  projectId,
  templateId,
  totalBaseContractValue,
  holdbackPercentage,
  scheduleOfValues,
  setLogValues,
  scheduleOfValueSectionMap,
}) => {
  const newBaseContractValues = [];
  const newSubContractValues = [];
  const newSubContractCOs = {};
  const newChangeOrderValues = [];
  const newNonHoldbackItems = [];
  const sectionValueMap = {};
  const newLiveSummaryValue = {
    projectId,
    templateId,
    totalContractValue: 0,
    totalContractAmount: totalBaseContractValue || 0,
    totalSubContractAmount: 0,
    totalChanges: 0,
    totalBilledToDate: 0,
    totalNonHoldbackAmount: 0,
    invoiceAmount: 0,
    currentContractValue: 0,
    baseContractProgressToDate: 0,
    subContractProgressToDate: 0,
    changeOrderProgressToDate: 0,
    nonHoldbackProgressToDate: 0,
    baseContractPreviousBillings: 0,
    subContractPreviousBillings: 0,
    changeOrderPreviousBillings: 0,
    nonHoldbackPreviousBillings: 0,
    baseContractInvoiceAmount: 0,
    subContractInvoiceAmount: 0,
    changeOrderInvoiceAmount: 0,
    nonHoldbackInvoiceAmount: 0,
    holdbackAmount: 0,
    holdbackPercentage: parseFloat(holdbackPercentage || 0),
  };

  const maxItemNumbers = {
    baseContract: 1,
    subContract: 1,
    subContractCOs: 1,
    changeOrder: 1,
    nonHoldback: 1,
  };

  let mostRecentValue;
  let values;

  Object.keys(scheduleOfValues).forEach((key) => {
    values = scheduleOfValues[key];

    if (!values.length) return;

    mostRecentValue = values[values.length - 1];

    if (
      mostRecentValue.projectId !== projectId
      && mostRecentValue.templateId !== templateId
    ) {
      return;
    }

    if (mostRecentValue.rowId === 'log') {
      if (setLogValues) {
        setLogValues(values);
      }
      return;
    }

    const itemNumberInt = parseInt(mostRecentValue.itemNumber, 10);
    const { sectionId } = mostRecentValue;
    const relevantSection = scheduleOfValueSectionMap[sectionId];

    if (mostRecentValue.isSubContract) {
      if (mostRecentValue.isChangeOrder) {
        if (!(mostRecentValue.parentRowId in newSubContractCOs)) {
          newSubContractCOs[mostRecentValue.parentRowId] = {
            changes: [],
            totalChanges: 0,
          };
        }
        newSubContractCOs[mostRecentValue.parentRowId].changes.push(mostRecentValue);
        newSubContractCOs[mostRecentValue.parentRowId]
          .totalChanges += mostRecentValue.contractAmount;

        newLiveSummaryValue.totalChanges += mostRecentValue.contractAmount;
        newLiveSummaryValue.changeOrderProgressToDate += mostRecentValue.progressToDate;
        newLiveSummaryValue.changeOrderPreviousBillings += mostRecentValue.previousBillings;
        newLiveSummaryValue.changeOrderInvoiceAmount += mostRecentValue.invoiceAmount;

        if (itemNumberInt >= maxItemNumbers.subContractCOs) {
          maxItemNumbers.subContractCOs = itemNumberInt + 1;
        }
      } else {
        newLiveSummaryValue.currentContractValue += mostRecentValue.contractAmount;
        newLiveSummaryValue.totalSubContractAmount += mostRecentValue.contractAmount;
        newLiveSummaryValue.subContractProgressToDate += mostRecentValue.progressToDate;
        newLiveSummaryValue.subContractPreviousBillings += mostRecentValue.previousBillings;
        newLiveSummaryValue.subContractInvoiceAmount += mostRecentValue.invoiceAmount;
        newSubContractValues.push(mostRecentValue);

        if (itemNumberInt >= maxItemNumbers.subContract) {
          maxItemNumbers.subContract = itemNumberInt + 1;
        }
      }
    } else if (mostRecentValue.isChangeOrder) {
      newLiveSummaryValue.totalChanges += mostRecentValue.contractAmount;
      newLiveSummaryValue.changeOrderProgressToDate += mostRecentValue.progressToDate;
      newLiveSummaryValue.changeOrderPreviousBillings += mostRecentValue.previousBillings;
      newLiveSummaryValue.changeOrderInvoiceAmount += mostRecentValue.invoiceAmount;
      newChangeOrderValues.push(mostRecentValue);

      if (itemNumberInt >= maxItemNumbers.changeOrder) {
        maxItemNumbers.changeOrder = itemNumberInt + 1;
      }
    } else if (mostRecentValue.excludeHoldback) {
      newLiveSummaryValue.totalNonHoldbackAmount += mostRecentValue.contractAmount;
      newLiveSummaryValue.nonHoldbackProgressToDate += mostRecentValue.progressToDate;
      newLiveSummaryValue.nonHoldbackPreviousBillings += mostRecentValue.previousBillings;
      newLiveSummaryValue.nonHoldbackInvoiceAmount += mostRecentValue.invoiceAmount;
      newNonHoldbackItems.push(mostRecentValue);

      if (itemNumberInt >= maxItemNumbers.nonHoldback) {
        maxItemNumbers.nonHoldback = itemNumberInt + 1;
      }
    } else if (mostRecentValue.sectionId) {
      if (relevantSection?.shouldBeInCalculations) {
        newLiveSummaryValue.currentContractValue += mostRecentValue.contractAmount;
      }

      if (!(sectionId in sectionValueMap)) {
        sectionValueMap[sectionId] = [];
        newLiveSummaryValue[`${sectionId}ProgressToDate`] = 0;
        newLiveSummaryValue[`${sectionId}PreviousBillings`] = 0;
        newLiveSummaryValue[`${sectionId}InvoiceAmount`] = 0;
      }

      newLiveSummaryValue[`${sectionId}ProgressToDate`] += mostRecentValue.progressToDate;
      newLiveSummaryValue[`${sectionId}PreviousBillings`] += mostRecentValue.previousBillings;
      newLiveSummaryValue[`${sectionId}InvoiceAmount`] += mostRecentValue.invoiceAmount;
      sectionValueMap[sectionId].push(mostRecentValue);

      if (itemNumberInt >= maxItemNumbers[sectionId] || !maxItemNumbers[sectionId]) {
        maxItemNumbers[sectionId] = itemNumberInt + 1;
      }
    } else {
      newLiveSummaryValue.currentContractValue += mostRecentValue.contractAmount;
      newLiveSummaryValue.baseContractProgressToDate += mostRecentValue.progressToDate;
      newLiveSummaryValue.baseContractPreviousBillings += mostRecentValue.previousBillings;
      newLiveSummaryValue.baseContractInvoiceAmount += mostRecentValue.invoiceAmount;
      newBaseContractValues.push(mostRecentValue);

      if (itemNumberInt >= maxItemNumbers.baseContract) {
        maxItemNumbers.baseContract = itemNumberInt + 1;
      }
    }

    if (!relevantSection || relevantSection.shouldBeInCalculations) {
      newLiveSummaryValue.totalBilledToDate += mostRecentValue.previousBillings;
      newLiveSummaryValue.invoiceAmount += mostRecentValue.invoiceAmount;
      newLiveSummaryValue.holdbackAmount += mostRecentValue.holdbackAmount;
    }
  });

  const {
    totalContractAmount,
    totalChanges,
  } = newLiveSummaryValue;
  newLiveSummaryValue.totalContractValue = totalContractAmount + totalChanges;
  newBaseContractValues.sort(itemNumberSort);
  newChangeOrderValues.sort(itemNumberSort);
  Object.keys(sectionValueMap).forEach((sectionId) => {
    sectionValueMap[sectionId].sort(itemNumberSort);
  });
  return {
    newBaseContractValues,
    newSubContractValues,
    newSubContractCOs,
    newChangeOrderValues,
    newNonHoldbackItems,
    newLiveSummaryValue,
    maxItemNumbers,
    sectionValueMap,
  };
};

export const getNumberText = ({ isChangeOrder, isSubContract, itemNumber } = {}) => {
  if (!itemNumber) return '';
  let prefix = '';
  if (isSubContract) prefix += 'S';
  if (isChangeOrder) prefix += 'CO';
  return `${prefix}${itemNumber}`;
};

export const getSubContractsWithTotalChanges = ({
  subContractValues = [],
  subContractCOs = {},
}) => (
  subContractValues.map((subcontract) => {
    const {
      [subcontract.rowId]: {
        totalChanges = 0,
      } = {},
    } = subContractCOs;
    return { ...subcontract, totalChanges };
  })
);

export const getSubContractsWithTotalChangesMap = ({
  projectIds,
  subContractMap,
  subContractCOMap,
}) => {
  if (!projectIds.length) return {};

  let allProjectSubContracts = [];

  projectIds.forEach((pId) => {
    const projectSubContracts = subContractMap?.[pId] ?? [];
    allProjectSubContracts = [...allProjectSubContracts, ...projectSubContracts];
  });

  return getSubContractsWithTotalChanges({
    subContractValues: allProjectSubContracts,
    subContractCOs: subContractCOMap,
  });
};

const clamp = (x) => Math.max(Math.min(x, 1), -1);

export const getUpdatedRow = ({
  row,
  payload,
  isProgress = false,
}) => {
  const updatedRow = {
    ...row,
    oldProgressToDate: !isNullOrUndefined(row.oldProgressToDate)
      ? row.oldProgressToDate
      : row.progressToDate,
    oldPercentageComplete: !isNullOrUndefined(row.oldPercentageComplete)
      ? row.oldPercentageComplete
      : row.percentageComplete,
    oldContractAmount: !isNullOrUndefined(row.oldContractAmount)
      ? row.oldContractAmount
      : row.contractAmount,
    isUncommitedRow: true,
  };

  if (!isProgress) {
    updatedRow.oldInvoiceAmount = !isNullOrUndefined(row.oldInvoiceAmount)
      ? row.oldInvoiceAmount
      : row.invoiceAmount;
  }

  Object.keys(payload).forEach((field) => {
    updatedRow[field] = payload[field];

    switch (field) {
      case 'invoiceAmount':
        updatedRow.progressToDate = payload.invoiceAmount + row.previousBillings;
        updatedRow.percentageComplete = clamp((
          updatedRow.progressToDate / updatedRow.contractAmount
        ).toFixed(2));
        break;
      case 'percentageComplete':
        if (row.contractAmount >= 0) {
          updatedRow.progressToDate = Math.min(
            (row.contractAmount * payload.percentageComplete).toFixed(2),
            row.contractAmount,
          );
        } else {
          updatedRow.progressToDate = Math.max(
            (row.contractAmount * payload.percentageComplete).toFixed(2),
            row.contractAmount,
          );
        }
        if (!isProgress) {
          const newInvoiceAmount = updatedRow.progressToDate - row.previousBillings;
          updatedRow.invoiceAmount = Math.round(newInvoiceAmount * 100) / 100;
        }
        break;
      case 'contractAmount':
        updatedRow.percentageComplete = clamp((
          updatedRow.progressToDate / payload.contractAmount
        ).toFixed(2));
        break;
      default:
        break;
    }
  });
  return updatedRow;
};
