import React from 'react';
import { Row, Select } from 'antd';
import PropTypes from 'prop-types';
import { Common } from 'ontraccr-common';

import OnTraccrTextInput from '../../../../common/inputs/OnTraccrTextInput';

import FieldTriggerCheckbox from '../FieldTriggerCheckbox';
import ConditionalRenderingRow from '../ConditionalRenderingRow';
import OptionalRow from '../OptionalRow';

import { getAttributeTypes, INVALID_TYPES } from './dynamicAttributes.constants';

function DynamicAttributeConfigure({
  t,
  id,
  setConfigProps,
  configProps = {},
  setCanSubmit,
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  contactAddressBooks = {},
  labels = [],
  buckets = [],
  ccCustomFields,
  userCustomFields,
  projectCustomFields,
} = {}) {
  const attributeOptions = getAttributeTypes(t);
  const {
    optional,
    hasConditionalRendering = false,
    conditionalRenderingFormula,
    dataType,
  } = configProps;

  const setTitle = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      title: value,
    });
    setCanSubmit(value);
  };

  const setConditionalRenderingFormula = (newVal) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: newVal,
    });
  };

  const updateCheckbox = (key) => (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      [key]: checked,
    });
  };

  const setDataType = (newDataType) => {
    const typeChanged = newDataType !== configProps.dataType;
    if (!typeChanged) return;
    setConfigProps({
      ...configProps,
      dataType: newDataType,
    });
  };

  let fields = [];
  let fieldMap = {};
  switch (dataType) {
    case 'projects':
      fields = projectCustomFields?.fields ?? [];
      break;
    case 'costcodes':
      fields = ccCustomFields?.fields ?? [];
      break;
    case 'users':
      fields = userCustomFields?.fields ?? [];
      break;
    default:
      fields = [];
  }

  const fieldOpts = fields
    .filter((field) => !INVALID_TYPES.has(field.selectedType))
    .map((field) => ({
      value: field.id,
      label: field.title,
    }));

  fieldMap = Common.getIdMap(fields);

  const setFieldId = (fieldId) => {
    const typeChanged = fieldId !== configProps.fieldId;
    if (!typeChanged) return;
    const targetField = fieldMap[fieldId];
    setConfigProps({
      ...configProps,
      fieldId,
      fieldType: targetField?.type,
      fieldDataType: targetField?.configProps?.dataType,
    });
  };

  return (
    <div>
      <Row className="form-required-field">
        Title:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <OnTraccrTextInput
          textarea
          placeholder="Insert title here"
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <OptionalRow onChange={updateCheckbox('optional')} optional={optional} disabled={disableOptional} />
      <ConditionalRenderingRow
        id={id}
        sections={sections}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
        onChange={updateCheckbox('hasConditionalRendering')}
        onFormulaChange={setConditionalRenderingFormula}
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
      />
      <Row className="form-required-field" style={{ marginTop: 10 }} >
        Type:
      </Row>
      <Row style={{ marginTop: 10 }} gutter={10}>
        <Select
          style={{ width: '100%', margin: '0px 10px' }}
          placeholder="Select attribute type"
          onChange={setDataType}
          value={configProps.dataType}
          optionFilterProp="label"
          options={attributeOptions}
        />
      </Row>
      <Row className="form-required-field" style={{ marginTop: 10 }}>
        Field:
      </Row>
      <Row style={{ marginTop: 10 }} gutter={10}>
        <Select
          style={{ width: '100%', margin: '0px 10px' }}
          placeholder="Select field"
          onChange={setFieldId}
          value={configProps.fieldId}
          optionFilterProp="label"
          options={fieldOpts}
        />
      </Row>
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={updateCheckbox('fieldTrigger')}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          isExternalForm={isExternalForm}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
    </div>
  );
}
/* eslint-disable react/forbid-prop-types */
DynamicAttributeConfigure.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  setConfigProps: PropTypes.func.isRequired,
  configProps: PropTypes.object,
  setCanSubmit: PropTypes.func.isRequired,
  sections: PropTypes.array,
  isExternalForm: PropTypes.bool,
  templateId: PropTypes.string.isRequired,
  divisions: PropTypes.array.isRequired,
  projectId: PropTypes.string,
  name: PropTypes.string,
  isBoardCards: PropTypes.bool,
  setFieldTriggerEditable: PropTypes.func.isRequired,
  disableOptional: PropTypes.bool,
  customers: PropTypes.array,
  projects: PropTypes.array,
  users: PropTypes.array,
  costcodes: PropTypes.array,
  phases: PropTypes.array,
  projectIdMap: PropTypes.object,
  vendors: PropTypes.array,
  equipment: PropTypes.array,
  formTemplates: PropTypes.array,
  contactAddressBooks: PropTypes.object,
  labels: PropTypes.array,
  buckets: PropTypes.array,
  ccCustomFields: PropTypes.array,
  userCustomFields: PropTypes.array,
  projectCustomFields: PropTypes.array,
};

DynamicAttributeConfigure.defaultProps = {
  configProps: {},
  sections: [],
  isExternalForm: false,
  projectId: null,
  name: '',
  isBoardCards: false,
  disableOptional: false,
  customers: [],
  projects: [],
  users: [],
  costcodes: [],
  phases: [],
  projectIdMap: {},
  vendors: [],
  equipment: [],
  formTemplates: [],
  contactAddressBooks: {},
  labels: [],
  buckets: [],
  ccCustomFields: [],
  userCustomFields: [],
  projectCustomFields: PropTypes.array,
};

export default DynamicAttributeConfigure;
