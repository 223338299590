import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Input, Checkbox, Divider, Button } from 'antd';
import { FixedSizeList as List } from 'react-window';

import { includesTerm } from '../../helpers/helpers';

export default ({
  clearFilters,
  confirm,
  filters = [],
  selectedKeys,
  setSelectedKeys,
  prefixCls,
  visible,
}) => {
  const [stagedSelection, setStagedSelection] = useState([]);
  const [searchStr, setSearchStr] = useState();

  const onSearchStrChanged = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchStr(value);
  },[])

  const onSelect = useCallback((checkKey) => (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    if (checked) {
      setStagedSelection(stagedSelection.concat([checkKey]));
    } else {
      setStagedSelection(stagedSelection.filter((key) => key !== checkKey));
    }
  },[stagedSelection])
  
  const onConfirm = useCallback(() => {
      setSelectedKeys(stagedSelection);
      confirm(true);
  },[confirm, setSelectedKeys, stagedSelection]);

  useEffect(() => {
    if(visible) {
      setStagedSelection(selectedKeys);
      setSearchStr();
    }
  },[visible, selectedKeys]);

  const checkSet = useMemo(() => new Set(stagedSelection),[stagedSelection]);

  const dataSource = useMemo(() => {
    if (!searchStr) return filters;
    return filters.filter((item) => includesTerm(item.text, searchStr));
  },[searchStr, filters]);

  return (
    <div className={prefixCls} style={{ padding: '2px 5px', maxHeight: 350, width: 400 }}>
        <Input.Search
          className='searchbar'
          placeholder="Search"
          onChange={onSearchStrChanged}
          allowClear
          style={{ marginBottom: 5 }}
          value={searchStr}
          onKeyDown={(e) => {
            /*
              AnalyticsBreakDownTag uses this in a dropdown, inside a Select
              If we dont stop propagation, the backspace is propagated to the Select
              and the tag is removed from the selected values 
            */
            e.stopPropagation();
          }}
        />
        <Divider style={{ margin: 0 }}/>


        <List
          height={Math.min(dataSource.length * 25, 250)}
          itemCount={dataSource.length}
          itemSize={25}
          width={380}
        >
         {
          ({ index, style }) => {
            const item = dataSource[index];
            return (
              <Row style={{ padding: '2px 7px', ...style }}>
              <Checkbox
                key={item.value}
                checked={checkSet.has(item.value)}
                onChange={onSelect(item.value)}
              >
                {item.text}
                </Checkbox>
            </Row>
            );
          }
         }
        </List>
        <Divider style={{ margin: 0 }} />
        <Row style={{ padding: '7px 8px 7px 3px' }} justify='space-between'>
          <Button
            size='small'
            disabled={selectedKeys.length === 0}
            type='text'
            onClick={clearFilters}
          >
            Reset
          </Button>
          <Button
            size='small'
            type='primary'
            onClick={onConfirm}
          >
            OK
          </Button>
        </Row>
        <div/>
    </div>
  )
}
