import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider, Row, Spin } from 'antd';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { TaskHelpers } from 'ontraccr-common';
import TimeTracking from '../state/timeTracking';

import TimeCardsHeader from './TimeCardsHeader';
import TimeCardRows from './TimeCardRows';
import TimeCardSlider from './TimeCardSlider/TimeCardSlider';

import {
  decorateTasks,
  getCanEditApproved,
  getCanEditUnapproved,
  getTimesFromTimeRange,
} from './timecard.helpers';

import Permissions from '../auth/Permissions';
import Analytics from '../helpers/Analytics';

import { sortByStartTime } from '../helpers/tasks';
import sortByString, { getIdMap, sortByCode } from '../helpers/helpers';
import {
  parseTimecards,
  findPayrollStartAndEndDates,
} from '../helpers/payroll';
import { getTimecardHistory, setTimeRange, updateCurrentTimeEntriesRange } from './state/timecards.actions';
import TimeCardsListView from './TimeCardsListView';
import TimeCardsSummaryView from './TimeCardsSummaryView';
import TimeCardFilterDrawer from './TimeCardFilterDrawer';
import { loadManualEntryData } from '../clock/ManualEntry/manualEntryHelpers';
import Colors from '../constants/Colors';
import {
  getTaskTime,
  ITEMS_PER_SUMMARY_PAGE,
  sortEntries,
  stringSortColumns,
  taskTimeColumns,
} from './timecardListSummaryView.helpers';
import { getFormApprovals, getFormById } from '../forms/state/forms.actions';
import FormDetailView from '../forms/CompletedForms/FormDetailView';
import calculateOT from '../helpers/overtime/calculateOT';
import { TIME_TYPE_FLAG } from '../constants/Flags';

export default function TimeCardsContainer({
  userId,
  isIndividual,
  isApprovals,
  hideWeekSelector,
  relevantUsers,
  fullUsers,
  isSummary,
}) {
  const ref = useRef();

  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.users);
  const {
    timeRange,
    firstPayrollDay,
    payPeriod,
    semiMonthlyPayPeriodDates,
  } = useSelector((state) => state.timecards);
  const approvals = useSelector((state) => state.approvals.approvals);
  const projects = useSelector((state) => state.projects.projects);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const timeCardHistory = useSelector((state) => state.timecards.history);
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);
  const pendingEntryUserMap = useSelector((state) => state.timeTracking.pendingEntryUserMap);
  const workingHours = useSelector((state) => state.settings.workingHours);
  const timeCardFilters = useSelector((state) => state.timecards.filters);
  const tablets = useSelector((state) => state.tablets);
  const company = useSelector((state) => state.settings.company);

  const {
    settings,
    paidFlags = [],
  } = useSelector((state) => {
    const {
      settings: {
        company: {
          settings: companySettings = {},
          paidFlags: companyPaidFlags,
        } = {},
      } = {},
    } = state;
    return {
      settings: companySettings,
      paidFlags: companyPaidFlags,
    };
  });

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [selectedDayKey, setSelectedDayKey] = useState();
  const [showSlider, setShowSlider] = useState(false);
  const [manualEntryDisplayMode, setManualEntryDisplayMode] = useState(true);
  const [viewType, setViewType] = useState(window.localStorage.getItem('timeCardViewType'));
  const [showFormDrawer, setShowFormDrawer] = useState(false);
  const [selectedSummaryRowKeys, setSelectedSummaryRowKeys] = useState([]);
  const [selectedSummaryTask, setSelectedSummaryTask] = useState();
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [entryDataMap, setEntryDataMap] = useState({});
  const [isCopying, setIsCopying] = useState(false);
  const [isRedistributingHours, setIsRedistributingHours] = useState(false);
  const [summaryPage, setSummaryPage] = useState(1);
  const [tableTasks, setTableTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFormDetail, setShowFormDetail] = useState(false);
  const [poCloseMode, setPoCloseMode] = useState(false);
  const [showOtBreakdown, setShowOtBreakdown] = useState(false);

  const onEditClicked = useCallback(() => setManualEntryDisplayMode(false), []);
  const hasPaidFlag = useMemo(() => {
    const paidFlagSet = new Set(paidFlags);
    return paidFlagSet.has(TIME_TYPE_FLAG);
  }, [paidFlags]);

  const resizeHandler = useCallback(() => {
    if (ref && ref.current) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    }
  }, [ref]);

  useEffect(() => {
    if (userId) {
      dispatch(getTimecardHistory(userId));
    }
  }, [userId]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  useEffect(() => {
    if (ref && ref.current) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    }
  }, [ref]);

  const approvalSet = useMemo(() => (
    new Set(approvals.map((approval) => approval.taskId))
  ), [approvals]);

  useEffect(() => {
    if (hideWeekSelector) return;
    const payload = { getRunningTasks: true };

    if (isSummary) {
      if (!relevantUsers?.length) return;
      payload.userIds = relevantUsers.map((u) => u.id);
    } else {
      if (!userId) return;
      payload.userId = userId;
    }

    let startTime = null;
    let endTime = null;

    if (!isApprovals) {
      const { startTime: ourStart, endTime: ourEnd } = getTimesFromTimeRange(timeRange);
      startTime = ourStart;
      endTime = ourEnd;
      payload.startTime = startTime;
      payload.endTime = endTime;
    } else {
      payload.ids = Array.from(approvalSet);
    }

    dispatch(TimeTracking.getTimeEntries(payload));
    if (startTime && endTime) {
      dispatch(updateCurrentTimeEntriesRange(
        DateTime.fromMillis(startTime),
        DateTime.fromMillis(endTime),
      ));
    }
  }, [timeRange, relevantUsers, userId, approvalSet, isSummary]);

  useEffect(() => {
    dispatch(TimeTracking.getPendingTimeEntries({
      userIds: relevantUsers.map((u) => u.id),
    }));
  }, [relevantUsers]);

  // reset pagination when one of view type, filters, or date range changes
  useEffect(() => {
    setSummaryPage(1);
  }, [isApprovals, timeCardFilters, timeRange]);

  useEffect(() => {
    if (hideWeekSelector) {
      dispatch(setTimeRange(findPayrollStartAndEndDates({
        payPeriodFirstDay: firstPayrollDay,
        payPeriod,
        semiMonthlyPayPeriodDates,
      })));
    }
  }, [hideWeekSelector, firstPayrollDay, payPeriod, semiMonthlyPayPeriodDates]);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const userMap = useMemo(() => getIdMap(users), [users]);
  const tabletMap = useMemo(() => getIdMap(tablets), [tablets]);
  const user = useMemo(() => {
    const {
      [userId]: ourUser = {},
    } = userMap;
    return ourUser;
  }, [userMap, userId]);

  const filterSets = useMemo(() => {
    const {
      users: filterUsers = [],
      type = [],
      projects: filterProjects = [],
      costcodes: filterCostcodes = [],
      status = [],
      enteredViaUser = [],
      enteredViaTablet = [],
      approvedBy = [],
    } = timeCardFilters ?? {};
    const costcodeIds = filterCostcodes.map((ccId) => {
      const splitCostcodeId = ccId.split('.') || [];
      return splitCostcodeId?.[1] ?? null;
    }).filter((id) => id);

    const usersSet = new Set(filterUsers);
    const typeSet = new Set(type);
    const projectsSet = new Set(filterProjects);
    const costcodesSet = new Set(costcodeIds);
    const statusSet = new Set(status);
    const enteredViaUserSet = new Set(enteredViaUser);
    const enteredViaTabletSet = new Set(enteredViaTablet);
    const approvedBySet = new Set(approvedBy);
    return {
      usersSet,
      typeSet,
      projectsSet,
      costcodesSet,
      statusSet,
      enteredViaUserSet,
      enteredViaTabletSet,
      approvedBySet,
    };
  }, [timeCardFilters]);

  const tasks = useMemo(() => {
    if (!isSummary) {
      const usersTasks = timeEntryUserMap?.[userId] ?? [];
      const usersPendingTasks = pendingEntryUserMap?.[userId] ?? [];
      return isApprovals
        ? usersTasks.filter((task) => approvalSet.has(task.id))
        : [...usersTasks, ...usersPendingTasks];
    }

    let tasksArr = [];
    relevantUsers.forEach((relevantUser) => {
      const userTasks = timeEntryUserMap?.[relevantUser.id] ?? [];
      const userPendingTasks = pendingEntryUserMap?.[relevantUser.id] ?? [];
      tasksArr = tasksArr.concat(TaskHelpers.filterSummaryTasks({
        tasks: [...userTasks, ...userPendingTasks],
        isApprovals,
        approvalSet,
        filterSets,
        timeRange,
      }));
    });

    return tasksArr;
  }, [
    isSummary,
    timeEntryUserMap,
    pendingEntryUserMap,
    userId,
    relevantUsers,
    filterSets,
    timeRange,
    isApprovals,
  ]);

  const canEditUnapproved = useMemo(() => {
    if (isSummary && selectedSummaryTask) {
      const {
        userId: selectedTaskUserId,
      } = selectedSummaryTask;
      const ourUser = userMap?.[selectedTaskUserId] ?? {};
      return getCanEditUnapproved(ourUser?.id, ourUser?.position);
    }
    if (isIndividual && Permissions.has('TIMECARD_EDIT_SELF')) return true;
    if (!user || !user.position) return false;
    return getCanEditUnapproved(user.id, user.position);
  }, [user, isIndividual, isSummary, selectedSummaryTask, userMap]);

  const canEditApproved = useMemo(() => {
    if (isSummary && selectedSummaryTask) {
      const {
        userId: selectedTaskUserId,
      } = selectedSummaryTask;
      const ourUser = userMap?.[selectedTaskUserId] ?? {};
      return getCanEditApproved(false, ourUser?.id, ourUser?.position);
    }
    if (!user || !user.position) return false;
    const {
      id,
      position,
    } = user;
    return getCanEditApproved(isIndividual, id, position);
  }, [user, isIndividual, isSummary, selectedSummaryTask, userMap]);

  const relevantTasks = useMemo(() => (
    decorateTasks({
      tasks,
      user: !isSummary ? user : {},
      checkGeofence: !isApprovals,
      projectIdMap,
      costcodeIdMap,
      isIndividual,
      canEdit: !isSummary ? canEditUnapproved : false,
      workingHours,
      userMap,
      tabletMap,
      isSummary,
    })
  ), [
    isApprovals,
    user,
    tasks,
    isIndividual,
    canEditUnapproved,
    projectIdMap,
    costcodeIdMap,
    workingHours,
    isSummary,
    userMap,
    tabletMap,
  ]);

  const { days = {}, payrollTasks = [] } = useMemo(() => {
    if (!payPeriod) return {};
    if (isSummary) return { payrollTasks: relevantTasks };
    const [firstDayOfRunningPayPeriod] = findPayrollStartAndEndDates({
      payPeriodFirstDay: firstPayrollDay,
      payPeriod,
      semiMonthlyPayPeriodDates,
    });

    if (isApprovals) {
      const seen = new Set();
      let duplicateId;
      const hasDuplicate = relevantTasks.some((task) => {
        const duplicated = seen.size === seen.add(task.id).size;
        if (duplicated) {
          duplicateId = task.id;
        }
        return duplicated;
      });

      if (hasDuplicate) {
        Sentry.withScope(() => {
          Sentry.captureException('duplicate approval', { duplicateId });
        });
      }
    }

    const {
      settings: {
        showOriginalTimes = false,
        useEndDateOvernight,
      } = {},
    } = company ?? {};

    return parseTimecards({
      timeRange,
      firstDayOfRunningPayPeriod,
      tasks: relevantTasks,
      taskHistory: timeCardHistory,
      isIndividual,
      isApprovals,
      showOriginalTimes,
      useEndDateOvernight,
    });
  }, [
    timeRange,
    firstPayrollDay,
    payPeriod,
    semiMonthlyPayPeriodDates,
    relevantTasks,
    timeCardHistory,
    isIndividual,
    isApprovals,
    isSummary,
    company,
  ]);

  const formattedPayrollTasks = useMemo(() => {
    if (!payrollTasks.length) return [];
    if (!isSummary) return payrollTasks;

    let [startDt, endDt] = timeRange;
    startDt = startDt.startOf('day');
    if (isApprovals) {
      let maxDt = endDt;
      let minDt = startDt;
      payrollTasks.forEach((entry) => {
        const entryDT = TaskHelpers.getTaskDate(entry);
        if (entryDT > maxDt) maxDt = entryDT;
        if (entryDT < minDt) minDt = entryDT;
      });
      startDt = minDt.startOf('day');
      endDt = maxDt.endOf('day');
    }
    return sortEntries({
      startDt,
      endDt,
      payrollTasks,
      userMap,
    });
  }, [payrollTasks, timeRange, isApprovals, isSummary, userMap]);

  useEffect(() => {
    setTableTasks(formattedPayrollTasks);
  }, [formattedPayrollTasks]);

  const currentPageTasks = useMemo(() => {
    if (!tableTasks.length) return [];
    if (!isSummary) return tableTasks;

    const startIndex = (summaryPage - 1) * ITEMS_PER_SUMMARY_PAGE;
    if (startIndex >= tableTasks.length) return [];

    const endIndex = Math.min(startIndex + ITEMS_PER_SUMMARY_PAGE, tableTasks.length);

    return tableTasks.slice(startIndex, endIndex);
  }, [tableTasks, isSummary, summaryPage]);

  const updateManualEntryData = useCallback(async (taskIds) => {
    if (!taskIds.length) return;

    let newDataMap = {};
    const { dataMap } = await loadManualEntryData(taskIds, false);
    newDataMap = dataMap;

    setEntryDataMap((prev) => ({
      ...prev,
      ...newDataMap,
    }));
  }, []);

  const getManualEntryData = async () => {
    const taskIds = currentPageTasks
      .map((task) => task.id)
      .filter((taskId) => !entryDataMap[taskId]);

    if (taskIds.length) {
      updateManualEntryData(taskIds);
    }
  };

  useEffect(() => {
    if (currentPageTasks.length) {
      getManualEntryData();
    }
  }, [currentPageTasks]);

  const selectedDay = days[selectedDayKey];

  const onAddEntry = useCallback(() => {
    if (!isSummary) {
      const dayKey = DateTime.local().toLocaleString(DateTime.DATE_MED);
      setSelectedDayKey(dayKey);
    } else {
      setSelectedDayKey();
    }
    setShowSlider(true);
    setManualEntryDisplayMode(false);
    setShowFormDrawer(true);
  }, [isSummary]);

  const onTimecardEditClicked = useCallback((dayKey) => {
    setSelectedDayKey(dayKey);
    setShowSlider(true);
    setManualEntryDisplayMode(false);
  }, []);

  const onSummaryTaskModify = useCallback((mode = 'edit') => (taskId) => {
    const ourTask = payrollTasks.find((task) => task.id === taskId);
    if (!ourTask) return;
    const entryDT = TaskHelpers.getTaskDate(ourTask);
    const ourDay = entryDT?.toLocaleString(DateTime.DATE_MED);
    setSelectedSummaryTask(ourTask);
    setSelectedDayKey(ourDay ?? DateTime.local().toLocaleString(DateTime.DATE_MED));
    setIsCopying(mode === 'copy');
    setIsRedistributingHours(mode === 'redistribute');
    setShowSlider(true);
    setManualEntryDisplayMode(false);
  }, [payrollTasks]);

  const onSummaryTaskClick = useCallback((taskId) => {
    const ourTask = payrollTasks.find((task) => task.id === taskId);
    if (!ourTask) return;
    const entryDT = TaskHelpers.getTaskDate(ourTask);
    const ourDay = entryDT?.toLocaleString(DateTime.DATE_MED);
    setSelectedSummaryTask(ourTask);
    setSelectedDayKey(ourDay ?? DateTime.local().toLocaleString(DateTime.DATE_MED));
    setShowSlider(true);
    setManualEntryDisplayMode(true);
  }, [payrollTasks]);

  const onDayClick = useCallback((dayKey) => {
    Analytics.track('Timecards/OpenSlider');
    setSelectedDayKey(dayKey);
    setShowSlider(true);
    setManualEntryDisplayMode(true);
  }, []);

  const onSliderClose = useCallback(() => {
    setSelectedDayKey();
    setSelectedSummaryTask();
    setShowSlider(false);
    setIsCopying(false);
  }, []);

  const onRowSelectChange = useCallback((newSelectedRowKeys) => {
    setSelectedSummaryRowKeys(newSelectedRowKeys);
  }, []);

  const onTableChange = useCallback((_a, _b, sorter) => {
    let sorted = [...formattedPayrollTasks];
    if (sorter.order === undefined) {
      setTableTasks(sorted);
      return;
    }

    const dataIndex = sorter.field;

    if (stringSortColumns.has(dataIndex)) {
      sorted = [...formattedPayrollTasks].sort(sortByString(dataIndex));
    } else if (taskTimeColumns.has(dataIndex)) {
      sorted = [...formattedPayrollTasks]
        .sort((a, b) => (getTaskTime(dataIndex, a) ?? 0) - (getTaskTime(dataIndex, b) ?? 0));
    } else if (dataIndex === 'duration' || dataIndex === 'ptoDuration') {
      sorted = [...formattedPayrollTasks]
        .sort((a, b) => ((a?.endTime ?? 0) - (a?.startTime ?? 0)) - ((b?.endTime ?? 0) - (b?.startTime ?? 0)));
    } else if (dataIndex === 'date') {
      sorted = [...formattedPayrollTasks].sort((a, b) => {
        const dateTimeA = TaskHelpers.getTaskDate(a);
        const dateTimeB = TaskHelpers.getTaskDate(b);

        return dateTimeA - dateTimeB;
      });
    } else if (dataIndex === 'costCode') {
      sorted = [...formattedPayrollTasks].sort(sortByCode('costcode_code'));
    }

    if (sorter.order === 'descend') {
      sorted.reverse();
    }
    setTableTasks(sorted);
  }, [formattedPayrollTasks]);

  const onFormClick = useCallback(async (formId) => {
    if (loading || !formId) return;
    Analytics.track('Forms/ViewSubmitted');
    setLoading(true);
    if (await dispatch(getFormById(formId, { getRecentDraft: true }))) {
      dispatch(getFormApprovals());
      setShowFormDetail(true);
    }
    setLoading(false);
  }, [loading]);

  const clearFormSelection = useCallback(() => setShowFormDetail(false), []);

  const initialTasks = useMemo(() => {
    if (isSummary && selectedSummaryTask) return [selectedSummaryTask];
    if (!selectedDay) return [];
    const { dayTasks = [] } = selectedDay;
    return [...dayTasks].sort(sortByStartTime);
  }, [selectedDay, canEditApproved, canEditUnapproved, isSummary, selectedSummaryTask]);

  const initialTaskHistory = useMemo(() => {
    if (!selectedDay) return [];
    const { dayHistory = [] } = selectedDay;
    return dayHistory;
  }, [selectedDay]);

  const defaultDate = useMemo(() => {
    if (selectedDay) return selectedDay?.date;
    if (!selectedDayKey) return undefined;

    const dateTime = DateTime.fromFormat(selectedDayKey, 'LLL d, yyyy');

    return dateTime?.toSQLDate();
  }, [selectedDay, selectedDayKey]);

  const headerTasks = useMemo(() => (
    currentPageTasks.filter((t) => !t.isPending)
  ), [currentPageTasks]);

  const style = useMemo(() => {
    let styleObj = {};

    if (isSummary) {
      styleObj = {
        overflow: 'hidden',
      };
    } else if (viewType === 'list') {
      styleObj = { overflowX: 'auto' };
    }

    return styleObj;
  }, [viewType, isSummary]);

  const hoursBreakdownMap = useMemo(() => {
    const calculatedOtTasksMap = {};

    let ourUsers = relevantUsers;
    if (!isSummary) ourUsers = [{ id: userId }];

    ourUsers.forEach((relevantUser) => {
      const usersTasks = timeEntryUserMap?.[relevantUser.id] ?? [];
      const userCalculatedOtTasksMap = calculateOT({
        tasks: usersTasks,
        settings,
        range: timeRange,
      }).reduce((acc, task) => {
        const {
          id,
          regularTime = 0,
          overtime = 0,
          doubleOT = 0,
          saturdayTime = 0,
          saturdayOT = 0,
          saturdayDoubleOT = 0,
          sundayTime = 0,
          sundayOT = 0,
          sundayDoubleOT = 0,
        } = task;
        acc[id] = {
          ...task,
          regularDuration: regularTime + saturdayTime + sundayTime,
          otDuration: overtime + saturdayOT + sundayOT,
          dtDuration: doubleOT + saturdayDoubleOT + sundayDoubleOT,
        };
        return acc;
      }, {});
      calculatedOtTasksMap[relevantUser.id] = userCalculatedOtTasksMap;
    });

    return calculatedOtTasksMap;
  }, [timeEntryUserMap, isSummary, userId, relevantUsers, timeRange]);

  return (
    <>
      <div ref={ref} style={{ width: '100%', height: '100%', ...style }}>
        <div
          style={{
            padding: 5,
            backgroundColor: Colors.BREADCRUMB_BACKGROUND,
            position: 'sticky',
            width: '100%',
            left: 0,
            top: 0,
            zIndex: 50,
          }}
        >
          <TimeCardsHeader
            isIndividual={isIndividual}
            isApprovals={isApprovals}
            tasks={headerTasks}
            allTasks={tableTasks}
            user={user}
            hideWeekSelector={hideWeekSelector}
            viewType={viewType}
            setViewType={setViewType}
            onAddEntry={onAddEntry}
            isSummary={isSummary}
            setShowFilterDrawer={setShowFilterDrawer}
            selectedSummaryRowKeys={selectedSummaryRowKeys}
            summaryPage={summaryPage}
            setSummaryPage={setSummaryPage}
            showOtBreakdown={showOtBreakdown}
            setShowOtBreakdown={setShowOtBreakdown}
            entryDataMap={entryDataMap}
            hoursBreakdown={hoursBreakdownMap}
          />
        </div>
        {viewType !== 'list' && !isSummary && (
          <div style={{ width: '-webkit-fill-available' }}>
            <Divider className="timecard-divider" />
            <div id="timecard-container">
              <TimeCardRows
                days={days}
                width={width}
                onClick={onDayClick}
                onEditClicked={onTimecardEditClicked}
                isApprovals={isApprovals}
                userId={userId}
                hoursBreakdownMap={hoursBreakdownMap}
                showOtBreakdown={showOtBreakdown}
                hasPaidFlag={hasPaidFlag}
              />
            </div>
            <Divider className="timecard-divider" />
          </div>
        )}
        {viewType === 'list' && !isSummary && (
          <TimeCardsListView
            days={days}
            onView={onDayClick}
            onEdit={onTimecardEditClicked}
            onFormClick={onFormClick}
            isApprovals={isApprovals}
            userId={userId}
            entryDataMap={entryDataMap}
            hoursBreakdown={hoursBreakdownMap}
          />
        )}
        {isSummary && (
          <TimeCardsSummaryView
            tasks={currentPageTasks}
            isApprovals={isApprovals}
            selectedSummaryRowKeys={selectedSummaryRowKeys}
            onRowSelectChange={onRowSelectChange}
            onSummaryTaskModify={onSummaryTaskModify}
            onSummaryRowClick={onSummaryTaskClick}
            entryDataMap={entryDataMap}
            onTableChange={onTableChange}
            onFormClick={onFormClick}
            height={height}
            hoursBreakdown={hoursBreakdownMap}
          />
        )}
      </div>
      <TimeCardSlider
        visible={showSlider}
        day={selectedDay}
        onClose={onSliderClose}
        user={user}
        personal={isIndividual}
        initialEntries={initialTasks}
        initialHistory={initialTaskHistory}
        defaultDate={defaultDate}
        isDisplay={manualEntryDisplayMode}
        onEditClicked={onEditClicked}
        canEditApproved={canEditApproved}
        canEditUnapproved={canEditUnapproved}
        isApprovals={isApprovals}
        showFormDrawer={showFormDrawer}
        setShowFormDrawer={setShowFormDrawer}
        isSummary={isSummary}
        updateManualEntryData={updateManualEntryData}
        isCopying={isCopying}
        setIsCopying={setIsCopying}
        isRedistributingHours={isRedistributingHours}
        setIsRedistributingHours={setIsRedistributingHours}
      />
      <TimeCardFilterDrawer
        visible={showFilterDrawer}
        onClose={() => setShowFilterDrawer(false)}
        relevantUsers={fullUsers}
      />
      <FormDetailView
        visible={showFormDetail}
        userMap={userMap}
        onClose={clearFormSelection}
        poCloseMode={poCloseMode}
        setPoCloseMode={setPoCloseMode}
      />
      {loading && (
        <Row justify="center" align="middle" className="form-loading-container">
          <Spin size="large" />
        </Row>
      )}
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
TimeCardsContainer.propTypes = {
  relevantUsers: PropTypes.array,
  fullUsers: PropTypes.array,
  hideWeekSelector: PropTypes.bool,
  isApprovals: PropTypes.bool,
  isIndividual: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  isSummary: PropTypes.bool,
};

TimeCardsContainer.defaultProps = {
  relevantUsers: [],
  fullUsers: [],
  hideWeekSelector: false,
  isApprovals: false,
  isIndividual: false,
  isSummary: false,
};
