import React, {
  useMemo, useCallback, useEffect, useState,
} from 'react';
import {
  Checkbox, Col, Divider, Row, Spin, Table, message,
} from 'antd';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { DateTime } from 'luxon';
import { isNullOrUndefined } from 'ontraccr-common/lib/Common';
import { InfoCircleTwoTone, ReloadOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import ResizeObserver from 'rc-resize-observer';

import TimeTracking from '../../state/timeTracking';

import TimeCardDatePicker from '../../timecards/TimeCardDatePicker';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import { CUSTOM_EXPORT_PROP_TYPE } from '../../settings/Exports/exports.constants';
import ReportExcelExport from '../reportExcelExport';
import Debouncer from '../../helpers/Debouncer';
import { request } from '../../helpers/requests';
import { getIdMap } from '../../helpers/helpers';
import { findPayrollStartAndEndDates } from '../../helpers/payroll';

import {
  constructAfterExportPayload,
  getBatchNumber,
  getDataKey,
  updateFormNumbers,
} from './exports.helpers';
import HoverHelp from '../../common/HoverHelp';
import { LINE_NUMBER_COLUMN, PATH_MAP, DATE_FORMAT_MAP } from './exports.constants';

import FormDetailView from '../../forms/CompletedForms/FormDetailView';
import TimeCardSlider from '../../timecards/TimeCardSlider/TimeCardSlider';

import { getFormById } from '../../forms/state/forms.actions';
import { getTimecardHistory } from '../../timecards/state/timecards.actions';
import BorderlessButton from '../../common/buttons/BorderlessButton';

const exporter = new ReportExcelExport();

const debouncers = {};

const dt = DateTime.local();
const endOfWeek = dt.endOf('week').minus({ day: 1 }); // Luxon week starts on Monday
const startOfWeek = dt.startOf('week').minus({ day: 1 });
function CustomExportsTable({
  selectedExport = {},
  scroll,
  onHeaderResize,
}) {
  const dispatch = useDispatch();

  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);
  const timeCardHistory = useSelector((state) => state.timecards.history);
  const payPeriod = useSelector((state) => state.timecards.payPeriod);
  const firstPayrollDay = useSelector((state) => state.timecards.firstPayrollDay);
  const semiMonthlyPayPeriodDates = useSelector((state) => (
    state.timecards.semiMonthlyPayPeriodDates
  ));

  const [hideExported, setHideExported] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowLoading, setRowLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [showFormDetail, setShowFormDetail] = useState(false);
  const [showTimeDetail, setShowTimeDetail] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [dateRange, setDateRange] = useState([
    startOfWeek,
    endOfWeek,
  ]);
  const [calendarType, setCalendarType] = useState('week');
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const [startDate, endDate] = dateRange ?? [];

  const users = useSelector((state) => state.users.users);
  const userMap = useMemo(() => getIdMap(users), [users]);

  const {
    id: exportId,
    title: exportTitle,
    columns: exportColumns = [],
    addLineNumbers = false,
    generateBatchNumber = false,
    generateFormNumber = false,
    summarizeEmployees = false,
    summarizeDays = false,
    summarizeProject = false,
    approvedOnly = false,
    divisionId = null,
    formTemplateId = null,
    type,
  } = selectedExport || {};

  const canClick = formTemplateId || (!summarizeEmployees && !summarizeDays && !summarizeProject);

  // Allows only detecting field changes to avoid re-rendering the table when renaming columns
  const fieldIds = useMemo(() => {
    const ids = [];

    exportColumns
      .forEach(({ field, fields = [] }) => {
        if (field) {
          ids.push(field);
        } else {
          ids.push(fields.join(':'));
        }
      });

    return ids
      .sort()
      .join(', ');
  }, [exportColumns]);

  const specialColumns = useMemo(() => {
    if (!exportId) return [];
    const innerSpecialColumns = [];
    if (addLineNumbers) innerSpecialColumns.push(LINE_NUMBER_COLUMN);
    innerSpecialColumns.push({
      title: '',
      render: (_1, record) => {
        const { exportIds = [], batchNumber } = record ?? {};
        if (!exportIds?.includes(exportId)) return null;
        const suffix = batchNumber ? ` in batch ${batchNumber}` : '';
        const recordType = type === 'forms' ? 'form' : 'time card';
        return (
          <HoverHelp
            content={`This ${recordType} has already been exported${suffix}`}
            Icon={InfoCircleTwoTone}
            iconProps={{ twoToneColor: '#fdb81b' }}
          />
        );
      },
      width: 50,
    });

    return innerSpecialColumns;
  }, [addLineNumbers, exportId, type]);

  const columns = useMemo(() => {
    if (!exportId) return [];

    const parsedColumns = [...specialColumns];

    exportColumns.forEach((col) => {
      parsedColumns.push({
        title: (
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {col.title}
          </div>
        ),
        dataIndex: getDataKey(col),
        width: 200,
      });
    });

    return parsedColumns;
  }, [specialColumns, exportColumns, exportId]);

  useEffect(() => {
    const fetchExportData = async () => {
      setLoading(true);
      const path = PATH_MAP[type];
      const params = {
        params: {
          startTime: startDate.toMillis(),
          endTime: endDate.toMillis(),
          exportId,
          divisionId,
          columns: exportColumns,
          timezone: DateTime.local().zoneName,
        },
      };

      if (type === 'forms') {
        params.params.formTemplateId = formTemplateId;
      }

      if (type === 'timeEntries') {
        params.params.approvedOnly = approvedOnly;
        params.params.summarizeEmployees = summarizeEmployees;
        params.params.summarizeDays = summarizeDays;
        params.params.summarizeProject = summarizeProject;
      }

      try {
        const { data: exportData = [] } = await axios.get(path, params);

        if (!Array.isArray(exportData)) {
          Sentry.withScope(() => {
            Sentry.captureException(new Error('Invalid export data received from route'), { path, params, exportData });
          });
          setRows([]);
        } else {
          setRows(exportData ?? []);
        }
      } catch (err) {
        message.error('Failed to load export data');
        Sentry.withScope(() => {
          Sentry.captureException(err);
        });
        setRows([]);
      } finally {
        setLoading(false);
      }
    };
    if (!(exportId in debouncers)) {
      debouncers[exportId] = new Debouncer();
    }

    debouncers[exportId].debounce(fetchExportData, 500);
    setShouldRefresh(false);
    return () => {
      debouncers[exportId]?.clear();
    };
  }, [
    // Avoid re-fetching data when the column title changes by only including fieldIds
    fieldIds,
    exportId,
    formTemplateId,
    approvedOnly,
    summarizeEmployees,
    summarizeDays,
    summarizeProject,
    divisionId,
    startDate,
    endDate,
    type,
    shouldRefresh,
  ]);

  const filteredRows = useMemo(() => {
    if (!hideExported) return rows;
    const filtered = rows.filter(({ exportIds }) => !exportIds.includes(exportId));
    return filtered;
  }, [rows, hideExported]);

  const onCalenderTypeChange = useCallback((newType) => {
    setCalendarType(newType);
    const [endDay] = dateRange;
    const now = DateTime.local();
    const realEnd = now > endDay ? now : endDay;
    let newRange = [...dateRange];
    if (newType === 'payPeriod') {
      newRange = findPayrollStartAndEndDates({
        startDay: realEnd,
        payPeriodFirstDay: firstPayrollDay,
        payPeriod,
        semiMonthlyPayPeriodDates,
      });
    } else if (newType === 'week') {
      newRange = [
        realEnd.startOf('week').minus({ day: 1 }),
        realEnd.endOf('week').minus({ day: 1 }),
      ];
    }
    if (newType !== 'custom') setDateRange(newRange);
  }, [
    dateRange,
    payPeriod,
    firstPayrollDay,
    semiMonthlyPayPeriodDates,
  ]);

  const showTimeSlider = useCallback(() => setShowTimeDetail(true), []);
  const hideTimeSlider = useCallback(() => setShowTimeDetail(false), []);

  const onReload = useCallback(() => setShouldRefresh(true), []);

  const onClick = useCallback(async (row) => {
    // Rows with table values have ids like 36hex-0, 36hex-1
    const [realId] = row.id?.split('-') ?? [];
    if (!realId) return;
    if (formTemplateId) {
      setRowLoading(true);
      const passed = dispatch(getFormById(realId));
      if (passed) setShowFormDetail(true);
      setRowLoading(false);
    } else if (canClick) {
      const ourId = row.taskId ?? realId;
      await dispatch(TimeTracking.getTimeEntries({ ids: [ourId], userId: row.userId }));
      setSelectedRow({ ...row, id: ourId });
      showTimeSlider();
    }
  }, [formTemplateId, canClick, showTimeSlider]);

  const onExport = useCallback(async () => {
    if (!selectedExport) return;
    let batchNumber;

    if (generateBatchNumber) {
      batchNumber = await getBatchNumber({ exportId });
      if (isNullOrUndefined(batchNumber)) return;
    }

    const uniqueExportFormats = new Set(exportColumns.filter((col) => col.field === 'exportDate').map((col) => col.filterValue ?? null));
    uniqueExportFormats.add(null);
    const exportDateColumns = Array.from(
      uniqueExportFormats,
    );

    let exportRows = filteredRows;

    const now = DateTime.local();

    if (exportDateColumns.length > 0) {
      exportRows = filteredRows.map((row) => {
        const newRow = { ...row };
        exportDateColumns.forEach((dateFormat) => {
          const dateKey = dateFormat ? `exportDate-${dateFormat}` : 'exportDate';
          const format = dateFormat ?? 'mm/dd/yyyy';
          const existing = newRow[dateKey] ?? '';
          const prefix = existing.length ? `${existing}, ` : '';
          const newDateText = now.toFormat(`${DATE_FORMAT_MAP[format]}  hh:mm a`);
          newRow[dateKey] = `${prefix}${newDateText}`;
        });
        return newRow;
      });
    }

    const exportConfig = {
      addLineNumbers,
      columns: exportColumns,
      data: exportRows,
      batchNumber,
    };

    const didExport = exporter.createCustomExport(exportTitle, exportConfig);

    if (didExport) {
      const payload = constructAfterExportPayload({
        type,
        rows: exportRows,
        generateFormNumber,
        batchNumber,
      });

      if (Object.keys(payload).length === 0) return;

      const { data: formNumberMap = {} } = await request({
        call: axios.put(`/exports/${exportId}/after`, payload),
        hideSuccessToast: true,
      });

      if (type === 'forms') {
        const updatedRows = updateFormNumbers({ data: exportRows, formNumberMap });
        setRows(updatedRows);
      } else if (exportDateColumns.length > 0) {
        setRows(exportRows);
      }
    }
  }, [
    selectedExport,
    generateBatchNumber,
    generateFormNumber,
    addLineNumbers,
    exportColumns,
    exportTitle,
    exportId,
    filteredRows,
    type,
  ]);

  const timeEntries = useMemo(() => {
    if (!selectedRow?.id) return [];
    const userTimeEntries = timeEntryUserMap?.[selectedRow.userId] ?? [];
    const ourEntry = userTimeEntries?.find((entry) => entry.id === selectedRow.id);
    return ourEntry ? [ourEntry] : [];
  }, [timeEntryUserMap, selectedRow]);

  const user = useMemo(() => (
    userMap[selectedRow?.userId] ?? {}
  ), [userMap, selectedRow]);

  const relevantTaskHistory = useMemo(() => {
    if (!selectedRow?.id) return [];
    return timeCardHistory.filter((t) => (
      t.taskId === selectedRow.id
    ));
  }, [timeCardHistory, selectedRow]);

  useEffect(() => {
    if (selectedRow?.userId) {
      dispatch(getTimecardHistory(selectedRow?.userId));
    }
  }, [selectedRow]);

  return (
    <>
      <ResizeObserver onResize={onHeaderResize}>
        <Row justify="space-between">
          <Col>
            <Row justify="start" gutter={16} align="middle">
              <TimeCardDatePicker
                onChange={setDateRange}
                value={dateRange}
                onTypeChange={onCalenderTypeChange}
                type={calendarType}
              />
              <Col>
                {
                  shouldRefresh
                    ? <Spin size="small" style={{ marginTop: 4, paddingLeft: 8 }} />
                    : (
                      <BorderlessButton
                        iconNode={<ReloadOutlined />}
                        onClick={onReload}
                        style={{ backgroundColor: 'transparent', paddingLeft: 0 }}
                      />
                    )
                }
              </Col>
            </Row>
          </Col>
          <Col>
            <Row justify="end" gutter={4} align="middle">
              <Col>
                <Checkbox
                  checked={hideExported}
                  onChange={(e) => setHideExported(e?.target?.checked)}
                >
                  Hide previous exports?

                </Checkbox>
              </Col>
              {exportId !== 'preview' && (
              <Col>
                <OnTraccrButton
                  title="Export"
                  onClick={onExport}
                  type="cancel"
                />
              </Col>
              )}
            </Row>
          </Col>
        </Row>
      </ResizeObserver>
      <Divider />
      {
        loading
          ? (
            <Row justify="center" align="middle" style={{ height: '100%', width: '100%' }}>
              <Col>
                <Spin />
              </Col>
            </Row>
          )
          : (
            <Table
              columns={columns}
              dataSource={filteredRows}
              pagination={false}
              scroll={{
                x: 'fit-content',
                y: scroll,
              }}
              rowKey="id"
              style={{
                overflowX: 'hidden',
              }}
              rowClassName={canClick ? 'file-table-row' : null}
              onRow={(entry) => ({
                onClick: canClick ? () => onClick(entry) : null,
              })}
              loading={rowLoading}
            />
          )
      }
      <FormDetailView
        userMap={userMap}
        visible={showFormDetail}
        onClose={() => setShowFormDetail(false)}
        startOnFields
      />
      <TimeCardSlider
        visible={showTimeDetail}
        onClose={hideTimeSlider}
        user={user}
        initialEntries={timeEntries}
        initialHistory={relevantTaskHistory}
        isDisplay
      />
    </>
  );
}

CustomExportsTable.propTypes = {
  selectedExport: CUSTOM_EXPORT_PROP_TYPE,
  scroll: PropTypes.string,
  onHeaderResize: PropTypes.func,
};

CustomExportsTable.defaultProps = {
  selectedExport: null,
  scroll: null,
  onHeaderResize: null,
};

export default CustomExportsTable;
