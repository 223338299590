import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import moment from 'moment';
import axios from 'axios';

import { Common, FormHelpers, TaskHelpers } from 'ontraccr-common';
import { message } from 'antd';
import { SUPPORTED_FONTS } from '../common/pdf/PDFDesigner/PDFDesigner.constants';
import { uploadFiles } from '../helpers/requests';
import { isNullOrUndefined } from '../helpers/helpers';
import { formatProjectLabelFromCompanySettings } from '../projects/projectHelpers';
import { isEntryTimeValid } from './FormBuilder/FormFields/TimeEntryTable/TimeEntryTable.helpers';
import { getTrueFileType } from '../files/fileHelpers';

// FORM RESPONDER MODES:
export const DEFAULT_FORM_RESPONDER_MODE = 'Default';
export const PO_FORM_RESPONDER_MODE = 'PO';
export const SUB_CONTRACT_FORM_RESPONDER_MODE = 'Sub-Contract';

// EDIT TYPES
export const EDIT_TYPES = {
  EDIT: 'edit',
  RESUBMIT: 'resubmission',
};

export const startsWithDollarSign = (value) => {
  if (value === null || value === undefined) return false;
  return value.toString().startsWith('$');
};

export const formatCurrencyCalculation = (value = '$ ') => {
  const [whole = '0', mantissa = ''] = value.substring(2).split('.');
  const newMantisa = `${mantissa}00`.substring(0, 2);
  return `$ ${whole || '0'}.${newMantisa}`; // If value = '$ ', whole is empty string
};

export const formatTableValues = (values = [], columns = []) => {
  const currencyTableCalcs = columns.reduce((acc, col) => {
    if (col.isCalculation && col.isCurrency) {
      acc.push(col.name);
    }
    return acc;
  }, []);
  if (currencyTableCalcs.length === 0) return values;
  return values.map((row) => {
    const newRow = { ...row };
    currencyTableCalcs.forEach((key) => {
      const value = row[key];
      const prefix = !startsWithDollarSign(value) ? '$ ' : '';
      newRow[key] = formatCurrencyCalculation(`${prefix}${value}`);
    });
    return newRow;
  });
};

// Backwards compatible.
// Versions <= 2.3.2 store dropdown values as strings, instead of objects
export const getDropdownId = (selectedItem) => (
  typeof selectedItem === 'string'
    ? selectedItem
    : selectedItem.id
);

let VALID_CHARACTERS = new Set();
const preloadValidTextCharacters = async () => {
  const fakeDOC = await PDFDocument.create();
  const fonts = await Promise.all(Object.keys(SUPPORTED_FONTS).map((fontName) => {
    const font = StandardFonts[fontName];
    return fakeDOC.embedFont(font, { subset: true });
  }));
  fonts.forEach((font) => {
    const fontChars = font.getCharacterSet();
    if (VALID_CHARACTERS.size > 0) {
      const charIntersection = new Set();
      fontChars.forEach((charCode) => {
        if (VALID_CHARACTERS.has(charCode)) {
          charIntersection.add(charCode);
        }
      });
      VALID_CHARACTERS = charIntersection;
    } else {
      // Add
      fontChars.forEach((charCode) => {
        VALID_CHARACTERS.add(charCode);
      });
    }
  });
};
preloadValidTextCharacters();

const findBadInCharacters = (text = '') => {
  if (!text || typeof text !== 'string') return [];
  const badChars = [];
  for (let i = 0; i < text.length; i += 1) {
    const charCode = text.charCodeAt(i);
    if (text[i] !== '\n' && !VALID_CHARACTERS.has(charCode)) {
      badChars.push(text[i]);
    }
  }
  return badChars;
};

const timeEntryKeysToIgnoreSet = new Set(['time', 'enteredVia']);

// checks if response object has any empty values
const hasEmptyColumn = ({
  columns,
  dataType,
  values,
}) => {
  let columnKeys = columns.map((column) => {
    if (dataType === 'Shifts') {
      if (column.key === 'users') return 'userIds';
      if (column.key === 'dates') return 'times';
    }
    if (dataType === 'Materials' && column.key === 'location') return 'locationId';
    return column.key;
  });

  if (dataType === 'TimeEntry') {
    columnKeys = columnKeys.filter((key) => !timeEntryKeysToIgnoreSet.has(key) && !key.startsWith('field-'));
  }

  const hasEmptyValue = values.some((value) => (
    columnKeys.some((key) => (
      Common.isEmptyInObject(value, key)
    ))
  ));

  return hasEmptyValue;
};

export const generateConfigPropsAndSettingsMap = (sections = []) => {
  const configPropMap = {};
  const settingsMap = {};

  sections?.forEach((section) => {
    const { fields = [], id, settings } = section;
    settingsMap[id] = { settings };
    fields.forEach((field) => {
      const { id: fieldId, configProps } = field;
      configPropMap[fieldId] = configProps;
    });
  });

  return {
    configPropMap,
    settingsMap,
  };
};

export const generateResponseMap = (sections = []) => sections?.reduce((acc, section) => {
  const { fields = [] } = section;
  fields.forEach((field) => {
    const { fieldId, response } = field;
    acc[fieldId] = response;
  });
  return acc;
}, {});

const checkErrorForField = ({
  field = {},
  errorMap = {},
  responses = {},
}) => {
  const newErrorMap = { ...errorMap };
  const {
    configProps = {},
    id,
    selectedType,
  } = field;
  const {
    optional,
    hasConditionalRendering,
    conditionalRenderingFormula,
  } = configProps;
  if (selectedType === 'text') {
    const {
      [id]: { value } = {},
    } = responses;
    const badCharacters = findBadInCharacters(value);
    if (badCharacters.length > 0) {
      newErrorMap[id] = `Found invalid characters: ${badCharacters.join(' ')}`;
      return newErrorMap;
    }
  }

  if (
    hasConditionalRendering
    && FormHelpers.isConditionalRenderingFormulaComplete(conditionalRenderingFormula)
    && !FormHelpers.executeConditionalRenderingFormula({
      formula: conditionalRenderingFormula,
      responses,
    })
  ) {
    return newErrorMap;
  }

  if (optional) {
    switch (selectedType) {
      case 'dateRange': {
        const {
          [id]: {
            startTime,
            endTime,
            repeat,
            repeatEndDate,
          } = {},
        } = responses;
        if ((!startTime && endTime) || (startTime && !endTime) || (repeat && !repeatEndDate)) {
          newErrorMap[id] = 'Please select a start and end time';
        }
        break;
      }
      case 'table': {
        // empty response for optional allowed
        if (!responses[id] || !responses[id].values || responses[id].values.length === 0) {
          break;
        }

        const {
          requiredColumns,
          dataType,
          columns = [],
        } = configProps;

        const {
          [id]: {
            values = [],
          } = {},
        } = responses;

        // if there is an entry and required columns, all columns must be filled
        if (requiredColumns) {
          if (hasEmptyColumn({ columns, dataType, values })) newErrorMap[id] = 'Please fill in all columns';
          break;
        }

        if (dataType === 'TimeEntry' && values.some((val) => Common.isEmptyInObject(val, 'date'))) {
          newErrorMap[id] = 'Please enter a valid date for time entries';
        }

        break;
      }
      default:
        break;
    }
    return newErrorMap;
  }
  switch (selectedType) {
    case 'yes-no': {
      const {
        explain = null,
      } = configProps;
      if (!responses[id] || !responses[id].value) {
        newErrorMap[id] = 'Please select an option';
      } else if (explain && explain.includes(responses[id].value) && !responses[id].explanation) {
        newErrorMap[id] = 'Please explain your selection';
      }
      break;
    }
    case 'gpsLocation':
    case 'dropdown': {
      const {
        [id]: {
          values = [],
        } = {},
      } = responses;
      if (configProps.openLimit) {
        if (!values || values.length === 0) {
          newErrorMap[id] = 'Please select an option';
        }
        break; // Allow any number of answers
      }

      // If not open limit, check for required number of answers
      const requiredNumAnswers = configProps.numAnswers ?? 1;

      if (!values || (values && values.length < requiredNumAnswers)) {
        newErrorMap[id] = `Please choose ${requiredNumAnswers} options`;
      }
      break;
    }
    case 'attribute':
    case 'text': {
      const { allowEdits = true, isNumerical } = configProps;
      const shouldEdit = allowEdits || selectedType !== 'attribute';
      if ((!responses[id] || isNullOrUndefined(responses[id].value)) && shouldEdit) {
        newErrorMap[id] = isNumerical
          ? 'Please enter a number'
          : 'Please enter your response';
      }
      break;
    }
    case 'multiSig': {
      const {
        [id]: {
          values = [],
        } = {},
      } = responses;
      const missingSigs = values.filter((val) => !val.sig);
      if (missingSigs.length > 0) {
        const names = missingSigs.map((val) => val.name).join(', ');
        newErrorMap[id] = `Missing signatures for: ${names}`;
      }
      break;
    }
    case 'attachment': {
      const files = responses[id] ? responses[id].files : [];
      if (!files || (files && !files.length)) {
        newErrorMap[id] = 'Please attach the corresponding file(s)';
      }
      break;
    }
    case 'table': {
      const {
        requiredColumns,
        dataType,
        columns = [],
      } = configProps;

      if (!responses[id] || !responses[id].values || responses[id].values.length === 0) {
        newErrorMap[id] = 'Please enter an entry';
      }

      const {
        [id]: {
          values = [],
        } = {},
      } = responses;

      if (requiredColumns) {
        if (hasEmptyColumn({ columns, dataType, values })) newErrorMap[id] = 'Please fill in all columns';
        break;
      }

      if (dataType === 'TimeEntry' && values.some((value) => Common.isEmptyInObject(value, 'date'))) {
        newErrorMap[id] = 'Please enter a valid date for time entries';
      }

      break;
    }
    case 'dateRange': {
      const {
        [id]: {
          startTime,
          endTime,
          repeat,
          repeatEndDate,
        } = {},
      } = responses;
      if (!(startTime && endTime)) {
        newErrorMap[id] = 'Please select a start and end time';
      } else if (repeat && !repeatEndDate) {
        newErrorMap[id] = 'Please select a repeat end date';
      }
      break;
    }
    case 'dateTime': {
      const {
        hideDate = false,
        hideTime = false,
      } = configProps;
      const {
        [id]: {
          date,
          time,
        } = {},
      } = responses;
      const needsBoth = !hideDate && !hideTime;
      const needsDate = !hideDate;
      const needsTime = !hideTime;
      if (needsBoth) {
        if (!date || !time) {
          newErrorMap[id] = 'Please select a date and time';
        }
      } else if (needsDate && !date) {
        newErrorMap[id] = 'Please select a date';
      } else if (needsTime && !time) {
        newErrorMap[id] = 'Please select a time';
      }
      break;
    }
    case 'weather': {
      const {
        [id]: {
          value,
        } = {},
      } = responses;
      if (!value) {
        newErrorMap[id] = 'Please set a weather location';
      }
      break;
    }
    default:
      break;
  }
  return newErrorMap;
};

export default {};

export const getErrorsFromResponses = ({
  sections,
  responses,
  sectionPermissionMap,
}) => {
  let errorMap = {};
  let wentThroughFields = false;
  if (!sections || !sections.length) return { errorMap, wentThroughFields: true };
  sections.forEach(({
    id,
    fields,
    hidden,
    settings: {
      hasConditionalRendering,
      conditionalRenderingFormula,
    } = {},
  }, index) => {
    const hasPermission = !sectionPermissionMap
      || (sectionPermissionMap[id]?.canView && sectionPermissionMap[id]?.canEdit);
    const isConditionallyVisible = (
      !hasConditionalRendering
        || !FormHelpers.isConditionalRenderingFormulaComplete(conditionalRenderingFormula)
        || FormHelpers.executeConditionalRenderingFormula({
          formula: conditionalRenderingFormula,
          responses,
        })
    );

    if (!hidden && hasPermission && isConditionallyVisible) {
      const safeFields = fields ?? [];
      safeFields.forEach((field) => {
        errorMap = checkErrorForField({ field, errorMap, responses });
      });
    }
    if (index === sections.length - 1) {
      wentThroughFields = true;
    }
  });
  return { errorMap, wentThroughFields };
};

export const prepareResponsePayload = ({
  sections = [],
  responses = {},
  title,
  templateId,
  assignedFormId,
  assignedDraftId,
  projectId,
  projectIds = [],
  bucketIds = [],
  customerId,
  customerIds = [],
  vendorId,
  cardId,
  valueFieldId,
  fieldTriggerMap = {},
}) => {
  const projectIdSet = new Set();
  const bucketIdSet = new Set();
  const customerIdSet = new Set();
  if (projectId) projectIdSet.add(projectId);
  if (projectIds) projectIds.forEach((id) => id && projectIdSet.add(id));
  if (bucketIds) bucketIds.forEach((id) => id && bucketIdSet.add(id));
  if (customerId) customerIdSet.add(customerId);
  if (customerIds) customerIds.forEach((id) => id && customerIdSet.add(id));

  const vendorIdSet = new Set();
  if (vendorId) vendorIdSet.add(vendorId);

  const collected = {};
  const payloadResponse = {};
  sections.forEach((section) => {
    const {
      fields = [],
      duplicatedParentId: sectionDuplicatedParentId,
      id: sectionId,
      name: sectionName,
    } = section;
    fields.forEach((field) => {
      const {
        configProps = {},
        selectedType,
        id: fieldId,
        duplicatedParentId,
      } = field;
      const {
        [fieldId]: response = {},
      } = responses;
      if (selectedType === 'dropdown') {
        const { dataType, shouldDisableSmartFiltering = false } = configProps;
        const { values = [] } = response;
        if (!shouldDisableSmartFiltering) {
          if (dataType === 'Customers') {
            values.forEach(({ id }) => customerIdSet.add(id));
          } else if (dataType === 'Projects') {
            values.forEach(({ id }) => projectIdSet.add(id));
          } else if (dataType === 'Vendors') {
            values.forEach(({ id }) => vendorIdSet.add(id));
          } else if (dataType === 'Buckets') {
            values.forEach(({ id }) => bucketIdSet.add(id));
          }
        }
      }
      const dynamicType = response.type ?? configProps?.fieldType ?? selectedType;
      payloadResponse[fieldId] = {
        ...(configProps),
        ...response,
        fieldId,
        type: selectedType === 'dynamicAttribute' ? dynamicType : selectedType,
        duplicatedParentId,
        sectionDuplicatedParentId,
        sectionId,
        sectionName,
      };
    });
  });

  collected.projectIds = [...projectIdSet];
  collected.vendorIds = [...vendorIdSet];
  collected.bucketIds = [...bucketIdSet];
  collected.customerIds = [...customerIdSet];
  return {
    collected,
    responses: payloadResponse,
    name: title,
    templateId,
    id: assignedFormId,
    draftId: assignedDraftId,
    cardId,
    valueFieldId,
    fieldTriggerMap,
  };
};

const getShallowFormFileFromMap = (fileId, fileMap = {}) => {
  const file = { ...fileMap[fileId] };
  const type = file.trueType ?? getTrueFileType(file);
  return {
    ...file,
    existing: true,
    type,
  };
};

export const preparePreloadedFormTriggerData = ({
  initialData = {},
  fileMap = {},
  sections: templateSections = [],
  templateProjectId,
  templateCustomerId,
  parentFormId,
  parentTemplateId,
  projectIdMap = {},
  customerIdMap = {},
  formTemplateMap = {},
  initialCardId,
  initialCardTitle,
}) => {
  const configMap = {};
  const newResponses = {};
  templateSections.forEach(({ fields: templateFields = [] }) => {
    templateFields.forEach((templateField) => {
      const { configProps = {}, id: templateFieldId, selectedType } = templateField;
      const {
        dataType,
      } = configProps;
      configMap[templateFieldId] = configProps;
      if ((!templateProjectId && !parentFormId && (!initialCardId || !initialCardTitle)) || selectedType !== 'dropdown') return;
      if (dataType !== 'Projects' && dataType !== 'Customers' && dataType !== 'CompletedForms' && dataType !== 'Cards') return;
      if (dataType === 'Projects' && templateProjectId) {
        const {
          [templateProjectId]: { name: projectName = '' } = {},
        } = projectIdMap;
        newResponses[templateFieldId] = {
          values: [{ id: templateProjectId, name: projectName }],
        };
      } else if (dataType === 'Customers' && templateCustomerId) {
        const {
          [templateCustomerId]: { name: customerName = '' } = {},
        } = customerIdMap;
        newResponses[templateFieldId] = {
          values: [{ id: templateCustomerId, name: customerName }],
        };
      } else if (dataType === 'CompletedForms' && parentFormId) {
        const {
          [parentTemplateId]: { name: templateName = '' } = {},
        } = formTemplateMap;
        newResponses[templateFieldId] = {
          values: [{ id: parentFormId, name: templateName }],
        };
      } else if (dataType === 'Cards' && initialCardId && initialCardTitle) {
        newResponses[templateFieldId] = {
          values: [{ id: initialCardId, name: initialCardTitle }],
        };
      }
    });
  });

  const {
    sections: initialSections = [],
  } = initialData || {};
  initialSections.forEach(({ fields = [] }) => {
    fields.forEach((field) => {
      const { fieldId, response, type } = field;
      if (type === 'attachment') {
        const { fileIds = [], timestamps } = response;
        newResponses[fieldId] = {
          files: fileIds.map((fileId) => getShallowFormFileFromMap(fileId, fileMap)),
          timestamps,
        };
      } else if (type === 'staticAttachments') {
        const { fileIds = [] } = response;
        newResponses[fieldId] = {
          files: fileIds,
        };
      } else if (type === 'multiSig') {
        const { values = [] } = response;
        newResponses[fieldId] = {
          values: values.map((obj) => {
            const { sig } = obj;
            const res = { ...obj };
            if (sig) res.sig = getShallowFormFileFromMap(sig, fileMap);
            return res;
          }),
        };
      } else if (type === 'table') {
        newResponses[fieldId] = {
          values: response.values,
          timezone: response.timezone,
        };
      } else if (type === 'yes-no') {
        const {
          [fieldId]: {
            explain = [],
          } = {},
        } = configMap;
        newResponses[fieldId] = {
          value: TaskHelpers.parseYesNoResponse(response),
        };
        if (explain.includes(newResponses[fieldId].value)) {
          newResponses[fieldId].explanation = response.explanation;
        }
      } else if (type === 'text') {
        if (response && response.value && typeof response.value === 'string') {
          const strippedValue = response.value.replace(/\r/g, '');
          newResponses[fieldId] = { ...response, value: strippedValue };
        } else {
          newResponses[fieldId] = response;
        }
      } else if (type === 'dropdown') {
        const {
          [fieldId]: {
            dataType,
          } = {},
        } = configMap;
        if (dataType === 'Costcodes' && response && response.values && Array.isArray(response.values)) {
          const newCostcodeResponseValues = response.values.map((value) => {
            const { id: costcodeId, name, phaseId } = value;
            return {
              name,
              id: phaseId === 'unphased' ? `unphased.${costcodeId}` : `${phaseId}.${costcodeId}`,
            };
          });
          newResponses[fieldId] = {
            ...response,
            values: newCostcodeResponseValues,
          };
        } else if (dataType === 'Cards' && (!response?.values || response?.values?.length === 0)) {
          // prevent triggered forms with no initial data to overwrite card pre-fill
          const templateValues = newResponses[fieldId]?.values ?? [];
          newResponses[fieldId] = {
            ...response,
            values: templateValues,
          };
        } else {
          newResponses[fieldId] = response;
        }
      } else {
        newResponses[fieldId] = response;
      }
    });
  });
  return newResponses;
};

export const parseFormResponseToReadableFormat = ({
  templateSections = [],
  responseSections = [],
  projectIdMap = {},
  settings = {},
  enableTable = false,
}) => {
  const configMap = {};
  const newResponses = {};
  templateSections.forEach(({ fields: templateFields = [] }) => {
    templateFields.forEach((templateField) => {
      const { configProps = {}, id: templateFieldId } = templateField;
      configMap[templateFieldId] = configProps;
      newResponses[templateFieldId] = '-';
    });
  });

  responseSections.forEach(({ fields = [] }) => {
    fields.forEach((field) => {
      const { fieldId, response, type } = field;

      switch (type) {
        case 'yes-no': {
          const {
            [fieldId]: {
              explain = [],
            } = {},
          } = configMap;

          newResponses[fieldId] = TaskHelpers.parseYesNoResponse(response);

          if (explain.includes(newResponses[fieldId])) {
            newResponses[fieldId] += ` - ${response.explanation}`;
          }
          break;
        }
        case 'text':
        case 'calculation':
        case 'attribute': {
          if (response?.value && typeof response.value === 'string') {
            const strippedValue = response.value.replace(/\r/g, '');
            newResponses[fieldId] = strippedValue;
          } else {
            newResponses[fieldId] = response?.value;
          }
          break;
        }
        case 'dateTime': {
          let value = '';
          const {
            timezone = DateTime.local().zoneName,
          } = response ?? {};

          if (response?.date) {
            value = `${DateTime.fromMillis(response.date, { zone: timezone }).toLocaleString(DateTime.DATE_MED)} `;
          }

          if (response?.time) {
            value += DateTime
              .fromMillis(response.time, { zone: timezone })
              .toLocaleString(DateTime.TIME_SIMPLE);
          }

          newResponses[fieldId] = value;
          break;
        }
        case 'dateRange': {
          const {
            timezone = DateTime.local().zoneName,
          } = response ?? {};

          const value = `${
            response?.startTime
              ? DateTime
                .fromMillis(response.startTime, { zone: timezone })
                .toLocaleString(DateTime.DATETIME_MED)
              : ''
          } - ${
            response?.endTime
              ? DateTime
                .fromMillis(response.endTime, { zone: timezone })
                .toLocaleString(DateTime.DATETIME_MED)
              : ''
          }`;

          newResponses[fieldId] = value;
          break;
        }
        case 'dropdown': {
          const { dataType } = configMap[fieldId] ?? {};
          if (response?.values && Array.isArray(response.values)) {
            newResponses[fieldId] = response.values.map((value) => {
              const { name, id } = value;
              if (dataType === 'Projects') {
                const { number } = projectIdMap[id] ?? {};
                return formatProjectLabelFromCompanySettings({ name, number, settings });
              }
              return name;
            }).join(', ');
          }
          break;
        }
        case 'table': {
          if (enableTable) {
            const formattedResponses = TaskHelpers.parseTableResponse(response, response.dataType);
            Object.keys(formattedResponses).forEach((colKey) => {
              const values = formattedResponses[colKey];
              newResponses[`${fieldId}-${colKey}`] = values.join(', ');
            });
          } else {
            newResponses[fieldId] = response;
          }
          break;
        }
        default:
          newResponses[fieldId] = response;
          break;
      }
    });
  });
  return newResponses;
};

// Edit this as needed
const MAX_FILE_GB = 5;

// Do not edit the following lines
const MAX_FILE_UNIT = 1024 * 1024 * 1024; // 1 GB
const MAX_FILE_SIZE = MAX_FILE_GB * MAX_FILE_UNIT;

export const constructFormPayloadForAPI = async ({
  form: {
    id,
    collected,
    responses,
    name,
    templateId,
    isOnEditStep,
    isResubmit,
    isEdit,
    cardId,
    valueFieldId,
    fieldTriggerMap = {},
    isDraft,
    draftId,
    companyId,
  },
  shouldUploadFiles = true,
  addSectionId = false,
  isExternalForm = false,
}) => {
  const sectionMap = {};
  let fileList = [];

  /*
    This relies on insertion order being correct
    to preserve ordering:
    https://stackoverflow.com/questions/5525795/does-javascript-guarantee-object-property-order

    We should refactor this to match the template properly.
  */
  const parsedFields = await Promise.all(Object.values(responses).map(async (field) => {
    const {
      type,
      title,
      dataType,
      fieldId,
      sectionId,
      duplicatedParentId,
    } = field;
    const response = {};

    switch (type) {
      case 'yes-no': {
        let fieldValue = field?.value;
        if (typeof fieldValue === 'boolean') {
          fieldValue = fieldValue ? 'yes' : 'no';
        }
        fieldValue = fieldValue?.toLowerCase();
        if (fieldValue === 'yes') {
          response.value = true;
        } else if (fieldValue === 'no') {
          response.value = false;
        } else if (fieldValue === 'n/a') {
          response.value = null;
        } else {
          response.value = undefined;
        }
        if (field.explanation) {
          response.explanation = field.explanation;
        }
        break;
      }
      case 'dropdown': {
        if (dataType !== 'Costcodes') {
          response.values = field.values;
          break;
        }
        if (field.values && Array.isArray(field.values)) {
          response.values = field.values.map(({ id: compoundKey, name }) => {
            const [phaseId, costcodeId] = compoundKey.split('.');
            return {
              id: costcodeId,
              phaseId,
              name,
            };
          });
        } else {
          response.values = [];
        }
        break;
      }
      case 'calculation':
      case 'attribute':
      case 'text': {
        const {
          value = '',
          isCurrency,
        } = field;
        const isCalc = type === 'calculation';
        const prefix = isCalc && isCurrency && !startsWithDollarSign(value) ? '$ ' : '';
        response.value = `${prefix}${value}`;
        if (isCalc && isCurrency) response.value = formatCurrencyCalculation(response.value);
        break;
      }
      case 'staticText': {
        const {
          value = '',
        } = field;
        response.value = value;
        break;
      }
      case 'multiSig': {
        const { values: sigs = [] } = field;
        const numSigs = sigs.length;
        if (numSigs > 0) {
          const fileIds = new Array(numSigs);
          const toUpload = [];
          const uploadedIndices = [];
          const filePrefix = `${id ?? DateTime.local().toMillis()}-${sectionId}-${fieldId}-`;
          const rawFiles = sigs
            .map(({ sig, id: sigUserId, autoSaveId }) => {
              if (!sig) return null;
              const { existing, id: fileId } = sig;
              const file = new File([sig], `${filePrefix}${sigUserId}.png`, { type: 'image/png' });
              if (fileId) file.id = fileId;
              if (existing) file.existing = existing;
              file.autoSaveId = autoSaveId;
              if (!shouldUploadFiles) {
                file.id = sigUserId;
              }
              return file;
            });
          rawFiles.forEach((file, idx) => {
            if (!file) return;
            fileIds[idx] = file.id;
            if (!file?.existing) {
              toUpload.push(file);
              uploadedIndices.push(idx);
            }
          });
          let files = [];
          if (shouldUploadFiles) {
            if (toUpload.length > 0) {
              files = await uploadFiles({
                files: toUpload,
                isDraft,
                formId: id,
                draftId,
              });
              files.forEach((file, idx) => {
                if (idx >= uploadedIndices.length) {
                  // Something bad happened
                  Sentry.withScope(() => {
                    Sentry.captureException(new Error('Error uploading files'), { sigs });
                  });
                  return;
                }
                const fileIdx = uploadedIndices[idx];
                if (fileIdx >= numSigs) {
                  // Something bad happened
                  Sentry.withScope(() => {
                    Sentry.captureException(new Error('Error uploading files'), { sigs });
                  });
                  return;
                }
                fileIds[fileIdx] = file.id || file.uid;
              });
            }
          } else {
            files = rawFiles;
          }
          response.values = sigs.map((obj, idx) => {
            const { sig } = obj;
            const res = { ...obj };
            if (sig) {
              const ourFileId = fileIds[idx];
              res.sig = ourFileId;
            }
            return res;
          });
          const filesWithoutAutosaved = files.filter((file) => file && !file?.isAutosaved);
          fileList = fileList.concat(filesWithoutAutosaved);
        } else {
          response.values = [];
        }
        break;
      }
      case 'attachment': {
        const { files: fieldFiles = [], timestamps } = field;
        const numFiles = fieldFiles.length;

        // Files uploaded to ant upload module have a uid.
        // Used in controlled mode for preloaded schedule "events"
        if (numFiles > 0) {
          const fileIds = new Array(numFiles);
          const toUpload = [];
          const uploadedIndices = [];

          if (isExternalForm) {
            const totalFileSize = fieldFiles.reduce((acc, file) => acc + file.size, 0);

            if (totalFileSize > MAX_FILE_SIZE) {
              const msg = `File size limit exceeded. Maximum file size is ${MAX_FILE_GB} GB`;
              message.error(msg);
              throw new Error(msg);
            }
          }

          fieldFiles.forEach((file, idx) => {
            if (file.existing) {
              fileIds[idx] = file.id || file.uid;
            } else {
              toUpload.push(file);
              uploadedIndices.push(idx);
            }
          });
          let files = [];
          if (shouldUploadFiles) {
            if (toUpload.length > 0) {
              files = await uploadFiles({
                files: toUpload,
                isDraft,
                formId: id,
                draftId,
                companyId,
                isExternalForm,
              });
            }
          } else {
            files = field.files;
          }
          files.forEach((file, idx) => {
            if (idx >= uploadedIndices.length) {
              // Something bad happened
              Sentry.withScope(() => {
                Sentry.captureException(new Error('Error uploading files'), { fieldFiles });
              });
              return;
            }
            const fileIdx = uploadedIndices[idx];
            if (fileIdx >= numFiles) {
              // Something bad happened
              Sentry.withScope(() => {
                Sentry.captureException(new Error('Error uploading files'), { fieldFiles });
              });
              return;
            }
            fileIds[fileIdx] = file.id || file.uid;
          });
          files = files.map((file, idx) => (
            { ...file, rotation: toUpload[idx]?.rotation }
          ));
          response.fileIds = fileIds;
          response.timestamps = timestamps;
          // filter here because we need the fileIds for the response
          const filesWithoutAutosaved = files.filter((file) => !file.isAutosaved);
          fileList = fileList.concat(filesWithoutAutosaved);
        } else {
          response.fileIds = [];
        }
        break;
      }
      case 'table': {
        const {
          values = [],
          createTimeEntry,
        } = field;

        let ourValues = field.dataType === 'Materials'
          ? values.map((val) => {
            const newVal = { ...val };
            delete newVal.locations;
            return newVal;
          })
          : values;

        // recursively call the function for time entry table custom fields
        if (createTimeEntry && dataType === 'TimeEntry') {
          const newTasks = [];
          let fullFiles = [];
          await Promise.all(
            values.map(async (task = {}) => {
              const {
                customData,
              } = task;

              const newTask = { ...task };

              if (customData && !Array.isArray(customData)) {
                const {
                  data: {
                    sections,
                  } = {},
                  files,
                } = await constructFormPayloadForAPI({
                  form: {
                    collected: {}, responses: customData,
                  },
                  addSectionId: true,
                });

                newTask.customData = sections;
                fullFiles = fullFiles.concat(files);
              }

              newTasks.push(newTask);
            }),
          );
          fileList = fileList.concat(fullFiles);
          ourValues = newTasks;
        }

        response.values = formatTableValues(ourValues, field.columns);
        response.columns = field.columns;
        response.dataType = field.dataType;
        response.timezone = field.timezone;
        break;
      }
      case 'dateRange': {
        response.startTime = field.startTime;
        response.endTime = field.endTime;
        response.repeat = field.repeat;
        response.timezone = field.timezone;
        if (field.repeatEndDate) {
          if (moment.isMoment(field.repeatEndDate)) {
            response.repeatEndDate = field.repeatEndDate.valueOf();
          } else {
            response.repeatEndDate = field.repeatEndDate;
          }
        }
        break;
      }
      case 'dateTime': {
        response.date = field.date;
        response.time = field.time;
        response.timezone = field.timezone;
        break;
      }
      case 'payment': {
        response.paymentId = field.paymentId;
        response.succeeded = field.succeeded;
        break;
      }
      case 'staticAttachments': {
        response.fileIds = field.files;
        break;
      }
      case 'gpsLocation': {
        response.values = field.values;
        break;
      }
      case 'weather': {
        response.value = field.value;
        response.showDailySnapshot = field.showDailySnapshot;
        response.showWeatherReport = field.showWeatherReport;
        break;
      }
      default:
        break;
    }

    const data = {
      title,
      type,
      response,
      fieldId,
      sectionId,
      duplicatedParentId,
    };

    return { data, field };
  }));

  parsedFields.forEach(({ data, field }) => {
    if (!data) return;
    const { sectionName, sectionDuplicatedParentId } = field;
    const key = data.sectionId || sectionName;
    if (!sectionMap[key]) {
      sectionMap[key] = {
        fields: [],
        name: sectionName,
        duplicatedParentId: sectionDuplicatedParentId,
      };
      if (addSectionId) sectionMap[key].id = data.sectionId;
    }
    sectionMap[key].fields.push(data);
  });

  const sections = Object.values(sectionMap);
  const payload = {
    templateId,
    data: {
      name,
      sections,
      collected,
    },
    files: fileList,
  };
  const ourId = id;
  if (ourId) payload.formId = ourId;
  if (isOnEditStep) payload.isOnEditStep = true;
  if (isResubmit) payload.isResubmit = true;
  if (isEdit) payload.isEdit = true;
  if (cardId) payload.cardId = cardId;
  if (valueFieldId) payload.valueFieldId = valueFieldId;
  if (Object.keys(fieldTriggerMap).length) payload.fieldTriggerMap = fieldTriggerMap;
  return payload;
};

export const formsValuesAreDifferent = ({
  initialData,
  fileMap,
  sections,
  responses,
}) => {
  // Check whether two forms have different responses
  const fieldMap = {};
  sections.forEach(({ fields = [] }) => {
    fields.forEach((field) => {
      const { id: fieldId } = field;
      fieldMap[fieldId] = field;
    });
  });

  let hasNewData = !initialData || !fileMap;
  if (!hasNewData) {
    const originalResponses = preparePreloadedFormTriggerData({
      initialData,
      fileMap,
      sections,
    });
    const { calculationFields } = FormHelpers.getCalculationFields({ sections, responses });
    const calculationFieldsIds = calculationFields.map((f) => f.id);
    hasNewData = Object.keys(responses).some((fieldId) => {
      if (calculationFieldsIds.includes(fieldId)) return false;
      if (!(fieldId in originalResponses)) return true;
      const {
        [fieldId]: field = {},
      } = fieldMap;
      const {
        [fieldId]: response = {},
      } = responses;
      const {
        [fieldId]: originalResponse = {},
      } = originalResponses;

      let ourType = field.selectedType;
      if (ourType === 'dynamicAttribute') {
        ourType = field.configProps?.fieldType ?? ourType;
      }

      switch (ourType) {
        case 'staticText':
        case 'staticAttachments':
          return false;
        case 'attribute':
        case 'text': return originalResponse.value !== response.value;
        case 'yes-no': {
          return response.value !== originalResponse.value
            || response.explanation !== originalResponse.explanation;
        }
        case 'attachment': {
          if (response?.files?.length !== originalResponse?.files?.length) return true;
          return false;
        }
        case 'dropdown': {
          const key = 'values';
          const hasSame = (response[key] && originalResponse[key])
            || (!response[key] && !originalResponse[key]); // XOR
          if (!hasSame) return true;
          if (!response[key]) return false; // Both empty;
          return response[key].length !== originalResponse[key].length
            || response[key].some((val, index) => (
              getDropdownId(val) !== getDropdownId(originalResponse[key][index])
            ));
        }
        case 'table': {
          const {
            values: rVals = [],
            columns = [],
          } = response;
          const {
            values: oVals = [],
          } = originalResponse;

          return rVals.length !== oVals.length
            || rVals.some((val, index) => (
              columns.some((col) => !oVals[index] || val[col.key] !== oVals[index][col.key])
            ));
        }
        case 'dateRange': {
          return response.startTime !== originalResponse.startTime
          || response.endTime !== originalResponse.endTime
          || response.repeat !== originalResponse.repeat
          || response.repeatEndDate !== originalResponse.repeatEndDate;
        }
        case 'dateTime': {
          return response.date !== originalResponse.date
          || response.time !== originalResponse.time;
        }
        default: return true;
      }
    });
  }
  return hasNewData;
};
export const floatIsBad = (text = '') => {
  if (!text) return true;
  const parsedFloat = parseFloat(text);
  if (!Number.isNaN(parsedFloat)) return false;
  const textString = text.toString();
  const len = textString.length;
  if (len === 1 && textString === '.') return true;
  if (textString.charAt(len - 1) === '.') return true;
  let decimalCount = 0;
  for (let i = 0; i < textString.length; i += 1) {
    const c = textString.charAt(i);
    if (c === '.') {
      decimalCount += 1;
      if (decimalCount > 1) return true;
    } else if (Number.isNaN(parseInt(c, 10))) {
      return true;
    }
  }
  return false;
};

export const validateTimeEntryTableResponses = ({ sections, responses }) => {
  let isValid = true;

  sections?.forEach(({ fields = [] } = {}) => {
    fields?.forEach(({
      id,
      selectedType,
      configProps: {
        dataType,
        enableWarningThreshold,
        warningThreshold,
      } = {},
      response,
    } = {}) => {
      const relevantResponse = response || responses[id];

      if (
        selectedType === 'table'
        && dataType === 'TimeEntry'
        && enableWarningThreshold
        && relevantResponse?.values?.length
        && isValid
      ) {
        const responseEntries = relevantResponse.values;
        isValid = responseEntries.some((entry) => isEntryTimeValid(entry, warningThreshold));
      }
    });
  });

  return isValid;
};
