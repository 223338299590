import { getAllTimesText } from '../../../../clock/ManualEntry/manualEntryHelpers';

export default {};

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'hh:mm A';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm';

export const DEFAULT_FIELD_TYPES = [
  'user',
  'date',
  'type',
  'division',
  'project',
  'phase',
  'costcode',
  'local',
  'class',
  'sageShift',
  'hourBased',
  'time',
  'note',
  'status',
  'enteredVia',
  'formattedDuration',
  'formattedStartTime',
  'formattedEndTime',
  'hourlyBillingRate',
  'userWage',
  'hourlyCost',
  'dailyCost',
];

export const EDITABLE_DEFAULT_FIELD_TYPES = [
  'date',
  'type',
  'project',
  'phase',
  'costcode',
  'hourBased',
  'time',
  'note',
  'class',
  'local',
  'hourlyBillingRate',
  'userWage',
  'hourlyCost',
  'dailyCost',
];

export const EDITABLE_CUSTOM_FIELD_TYPES = new Set([
  'text',
  'yes-no',
  'calculation',
  'dropdown',
  'dateRange',
  'dateTime',
  'attribute',
]);

export const PERMISSION_EXCLUDED_COLUMNS = new Set([
  'date',
  'user',
  'project',
  'phase',
  'costcode',
  'time',
]);

export const getCustomRenderMap = (companySettings) => ({
  formattedStartTime: (record) => (_, tableRecord) => {
    const relevantRecord = record ?? tableRecord;
    if (!relevantRecord) return null;

    return getAllTimesText({
      ...relevantRecord,
      endTime: null,
      originalEnd: null,
      breakEndTime: null,
      otEndTime: null,
      doubleOTEndTime: null,
      ptoEndTime: null,
    }, {
      returnComponent: true,
      showRegularTypeText: false,
      addTypeAsSuffix: true,
      showOriginalTimes: companySettings?.showOriginalTimes,
    });
  },
  formattedEndTime: (record) => (_, tableRecord) => {
    const relevantRecord = record ?? tableRecord;
    if (!relevantRecord) return null;

    return getAllTimesText({
      ...relevantRecord,
      startTime: null,
      originalStart: null,
      breakStartTime: null,
      otStartTime: null,
      doubleOTStartTime: null,
      ptoStartTime: null,
    }, {
      returnComponent: true,
      showRegularTypeText: false,
      addTypeAsSuffix: true,
      showOriginalTimes: companySettings?.showOriginalTimes,
    });
  },
});
