import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Drawer, message, } from 'antd';
import {
  EyeOutlined,
  SaveOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { FormHelpers } from 'ontraccr-common';
import { DateTime } from 'luxon';

import FullPhoto from '../common/photos/FullPhoto';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import DrawerFooter from '../common/containers/DrawerFooter';
import { getFileType, downloadFile } from '../files/fileHelpers';
import SignatureModal from './SignatureModal';
import FormResponseFields from './FormResponseFields';

import ResubmitModal from './ResubmitModal';
import generatePDFPreview from './generatePDFPreview';

import FormApprovalButton, {
  APPROVE_FORM_SUBMISSION, REJECT_FORM_SUBMISSION,
} from './FormApprovalButton';

import {
  submitForm,
  deleteDraft,
  clearFormSelection,
  getSharedFormDraftChildren,
} from './state/forms.actions';

import {
  getErrorsFromResponses,
  prepareResponsePayload,
  preparePreloadedFormTriggerData,
  formsValuesAreDifferent,
  DEFAULT_FORM_RESPONDER_MODE,
  PO_FORM_RESPONDER_MODE,
  SUB_CONTRACT_FORM_RESPONDER_MODE,
  validateTimeEntryTableResponses,
} from './ResponderHelpers';
import { getIdMap, uuid } from '../helpers/helpers';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import FormReassignmentDrawer from './FormReassignmentDrawer';
import {
  clearConditionalResponses,
  getFormattedCurrency,
  getSectionPermissionMap,
} from './formHelpers';
import Permissions from '../auth/Permissions';

const AUTOSAVE_INTERVAL = 15000;

const createTitle = ({
  mode,
  title,
  number,
  formValue,
  costToDate,
  poCloseMode,
}) => {
  let prefix = '';
  if (mode === PO_FORM_RESPONDER_MODE) {
    prefix = 'Add PO: ';
  } else if (mode === SUB_CONTRACT_FORM_RESPONDER_MODE) {
    prefix += 'Add Sub-Contract: ';
  }
  let poSuffix = '';
  if (poCloseMode) {
    const poValueCurr = getFormattedCurrency(formValue);
    const costToDateCurr = getFormattedCurrency(costToDate);
    const committedCurr = getFormattedCurrency(formValue - costToDate);
    poSuffix = ` (PO Value: ${poValueCurr}, Cost to Date: ${costToDateCurr}, Committed Cost: ${committedCurr})`;
  }
  const formTitleComp = title ?? '';
  const titleSuffix = number ? ` - ${number}` : '';
  return `${prefix}${formTitleComp}${titleSuffix}${poSuffix}`;
};

export default function FormResponder({
  visible = false,
  mode = DEFAULT_FORM_RESPONDER_MODE,
  onClose,
  history,
  redirectUrl = '/forms/log',
  assignedForm: {
    id: assignedFormId,
    draftId: assignedDraftId,
  } = {},
  isOnEditStep,
  isResubmit,
  isEdit,
  onSubmit: controlledSubmit,
  controlledData,
  controlledMode, // Used to override dispatching events from here.
  preloadedData,
  preloadedIds,
  cardId,
  draftDisabled = false,
  formSelector, // Allows user to select desired form
  lockedProjectId,
  showAsContainer = false,
  isExternalForm = false,
  previewDisabled = false,
  header,
  hideCancelButton = false,
  submitButtonStyle,
  cancelButtonStyle,
  initialFileMap,
  childSubmitMode,
  poCloseMode,
  hasLoadedPayableTemplate,
  isCopy,
}) {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const customers = useSelector((state) => state.customers.customers);
  const selectedFormTemplate = useSelector((state) => state.forms.selectedFormTemplate);
  const selectedForm = useSelector((state) => state.forms.selectedForm);
  const triggeredForms = useSelector((state) => state.forms.triggeredForms);
  const formApprovals = useSelector((state) => state.forms.approvals);
  const circularFieldIds = useSelector((state) => state.forms.circularFieldIds);
  const needsApproval = formApprovals?.[assignedFormId]?.editableApprovals;

  const selectedFormOverride = useMemo(() => (
    childSubmitMode ? {} : selectedForm
  ), [childSubmitMode, selectedForm]);

  const {
    manual: manualDrafts = {},
    assigned: assignedDrafts = {},
  } = useSelector((state) => state.forms.drafts);
  const {
    company: {
      settings = {},
      companyImageURL: logo,
    } = {},
  } = useSelector((state) => state.settings);

  const { id: selectedFormId } = selectedFormOverride || {};

  useEffect(() => {
    if (visible && assignedFormId) {
      dispatch(getSharedFormDraftChildren(assignedFormId));
    }
  }, [assignedFormId, visible]);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);

  const {
    id,
    title,
    formData = {},
    fileMap,
    initialData,
    initialCustomerIds,
    initialProjectIds,
    initialCostcodeIds,
    initialVendorIds = [],
    initialBucketIds,
    initialCardId,
    initialCardTitle,
    drawOptions = [],
    draftId,
    formNumber,
    templateProjectId,
    templateCustomerId,
    valueFieldId,
    useStandardTemplate,
    formUsers = [],
    divisionId,
    lastUpdatedDraft,
    parentForm,
    createTimeTableIds,
  } = useMemo(() => {
    // Manual form template
    if (!assignedFormId && !isCopy) {
      const {
        id: templateId,
        name: templateTitle,
        number,
        fileMap: templateFileMap = {},
        formData: templateFormData = {},
        drawOptions: templateDrawOptions = [],
        projectId: templateProjectId,
        valueField: valueFieldId,
        useStandardTemplate: useStandard,
        divisionId: templateDivisionId,
        createTimeTableIds: templateTimeTableIds,
      } = selectedFormTemplate || {};

      const {
        [templateProjectId]: { customerId: templateCustomerId } = {},
      } = projectIdMap;
      const {
        [assignedDraftId]: {
          id: draftId,
          customers: draftCustomerIds = [],
          vendors: draftVendorIds = [],
          data: draftData,
          lastUpdated: draftUpdateTS = 0,
          projects: draftProjectIds,
          buckets: draftBucketIds,
          cardId: draftCardId,
        } = {},
      } = manualDrafts;

      const safeFileMap = initialFileMap ?? {};

      let initData = (draftData || preloadedData || undefined);
      let initFileMap = {
        ...safeFileMap,
        ...templateFileMap,
      };
      if (controlledMode) {
        const { data: controlledInitData, fileMap: controlledFileMap = {} } = controlledData;
        initData = controlledInitData;
        initFileMap = {
          ...initFileMap,
          ...controlledFileMap,
        };
      }

      const {
        customerIds: preloadedCustomerIds,
        projectIds: preloadedProjectIds,
        vendorId: preloadedVendorId,
        costcodeId: preloadedCostcodeId,
        parentFormId: preloadedParentFormId,
        parentTemplateId: preloadedParentTemplateId,
        bucketIds: preloadedBucketIds,
      } = preloadedIds || {};

      const newSections = FormHelpers.createDuplicateSectionsFromDraftData(
        templateFormData,
        initData,
      );
      const selectedTemplateProjectId = templateProjectId ? [templateProjectId] : [];
      return {
        id: templateId,
        title: createTitle({
          mode,
          title: templateTitle,
          number,
        }),
        formNumber: number,
        formData: {
          ...templateFormData,
          sections: newSections,
        },
        drawOptions: templateDrawOptions,
        fileMap: initFileMap,
        initialData: initData,
        draftId: draftData ? draftId : null,
        initialProjectIds: preloadedProjectIds ?? draftProjectIds ?? selectedTemplateProjectId,
        initialVendorIds: preloadedVendorId ? [preloadedVendorId] : draftVendorIds,
        initialCostcodeIds: preloadedCostcodeId ? [preloadedCostcodeId] : [],
        initialCustomerIds: preloadedCustomerIds ?? draftCustomerIds ?? templateCustomerId,
        initialCostcodeId: preloadedCostcodeId,
        initialBucketIds: preloadedBucketIds ?? draftBucketIds,
        templateProjectId,
        templateCustomerId,
        valueFieldId,
        useStandardTemplate: useStandard,
        divisionId: templateDivisionId,
        lastUpdatedDraft: draftUpdateTS,
        parentForm: childSubmitMode ? {
          id: preloadedParentFormId,
          templateId: preloadedParentTemplateId,
        } : undefined,
        initialCardId: draftCardId,
        createTimeTableIds: templateTimeTableIds,
      };
    }
    // Assigned form, with data (maybe)
    const {
      templateId,
      templateName,
      templateSchema = {},
      data = {},
      fileMap: finishedMap,
      customers: formCustomers = [],
      projects: formProjects = [],
      costcodes: formCostcodes = [],
      drawOptions: formDrawOptions = [],
      number,
      lastUpdated: formUpdateTS = 0,
      useStandardTemplate: useStandard,
      valueField: valueFieldId,
      users,
      divisionId: formDivisionId,
      buckets: formBuckets = [],
      cardId: formCardId,
      cardTitle: formCardTitle,
      formValue,
      costToDate,
      createTimeTableIds: formTimeTableIds,
    } = selectedFormOverride;
    const {
      [assignedFormId]: {
        id: draftId,
        data: draftData = data,
        lastUpdated: draftUpdateTS = 0,
        projects: draftProjects = [],
        customers: draftCustomers = [],
        buckets: draftBuckets = [],
        cardId: draftCardId,
      } = {},
    } = assignedDrafts;

    const fullCostcodeIds = formCostcodes?.map(({
      costcodeId: formCostcodeId,
      phaseId: formPhaseId,
    }) => (
      formCostcodeId ? `${formPhaseId ?? 'unphased'}.${formCostcodeId}` : null
    )).filter((val) => val);

    const draftIsNewer = draftUpdateTS > formUpdateTS;

    const existingCircularFieldIds = draftData?.circularFieldIds ?? [];
    if (circularFieldIds.length) {
      const newSections = FormHelpers.injectConfigIntoSections({
        sections: templateSchema?.sections,
        fieldTypes: ['table'],
        dataTypes: ['Materials', 'Equipment'],
        ids: existingCircularFieldIds,
        config: { preventEdits: true },
      });
      templateSchema.sections = newSections;
    }

    const newSections = FormHelpers.createDuplicateSectionsFromDraftData(templateSchema, draftData);
    return {
      id: templateId,
      title: createTitle({
        mode,
        title: templateName,
        number,
        formValue,
        costToDate,
        poCloseMode,
      }),
      formData: {
        ...templateSchema,
        sections: newSections,
      },
      fileMap: finishedMap,
      initialData: (draftIsNewer ? draftData : data) ?? {},
      initialCustomerIds: draftIsNewer ? draftCustomers : formCustomers,
      initialProjectIds: draftIsNewer ? draftProjects : formProjects,
      initialCostcodeIds: fullCostcodeIds,
      initialBucketIds: draftIsNewer ? draftBuckets : formBuckets,
      formNumber: number,
      drawOptions: formDrawOptions,
      draftId: draftIsNewer && draftData !== data ? draftId : null,
      useStandardTemplate: useStandard,
      valueFieldId,
      formUsers: users,
      divisionId: formDivisionId,
      lastUpdatedDraft: draftIsNewer ? draftUpdateTS : formUpdateTS,
      initialCardId: draftIsNewer ? draftCardId : formCardId,
      initialCardTitle: draftIsNewer ? null : formCardTitle,
      createTimeTableIds: formTimeTableIds,
    };
  }, [
    assignedFormId,
    assignedDraftId,
    selectedFormTemplate,
    selectedFormOverride,
    assignedDrafts,
    manualDrafts,
    controlledData,
    controlledMode,
    projectIdMap,
    preloadedData,
    preloadedIds,
    mode,
    initialFileMap,
    childSubmitMode,
    poCloseMode,
    isCopy,
  ]);

  const {
    sections: formSections = [],
    collected: {
      employeeSignature: { collect: collectSig } = {},
    } = {},
    allowReassignment,
  } = formData;

  const [responses, setResponses] = useState({});
  const [fieldTriggerMap, setFieldTriggerMap] = useState({});
  const [submitTried, setSubmitTried] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState();
  const [customerIds, setCustomerIds] = useState(initialCustomerIds?.length
    ? initialCustomerIds
    : []);
  const [projectIds, setProjectIds] = useState(initialProjectIds?.length
    ? initialProjectIds
    : [lockedProjectId]);
  const [bucketIds, setBucketIds] = useState(initialBucketIds);
  const [vendorId, setVendorId] = useState(initialVendorIds?.[0] ?? null);
  const [showSigModal, setShowSigModal] = useState(false);
  const [lastDraft, setLastDraft] = useState();
  const [showReassignDrawer, setShowReassignDrawer] = useState();
  const [fileIsPreview, setFileIsPreview] = useState(false);
  const [sections, setSections] = useState(formSections);
  const [autosaveEnabled, setAutosaveEnabled] = useState(true); // if autosave is enabled
  const [shouldAutosave, setShouldAutosave] = useState(false); // toggle time interval autosaves
  const [isAutosaving, setIsAutosaving] = useState(false); // if autosave is in progress
  const [sessionDraftId, setSessionDraftId] = useState(uuid());

  const currentUser = useSelector((state) => state.profile.profile);

  useEffect(() => {
    setSections(formSections);
  }, [formData]);

  const onDownload = useCallback(async () => {
    await downloadFile({ fileObject: selectedFile });
  }, [selectedFile]);

  const onShowReassignClicked = useCallback(() => setShowReassignDrawer(true), []);
  const onCloseReassign = useCallback(() => setShowReassignDrawer(false), []);

  const closeSigModal = useCallback(() => setShowSigModal(false), []);
  const onCloseFile = useCallback(() => {
    setSelectedFile();
    setFileIsPreview(false);
  }, []);

  const selectedFileType = useMemo(() => (selectedFile
    ? getFileType(selectedFile)
    : null
  ), [selectedFile]);

  const onPreviewClicked = useCallback(async () => {
    setFileIsPreview(true);
    setSelectedFile(await generatePDFPreview({
      title,
      formData: {
        ...formData,
        sections,
      },
      useStandardTemplate,
      drawOptions,
      currentUser,
      sections,
      responses,
      fileMap,
      formNumber,
      settings,
      projectIdMap,
      logo,
    }));
  }, [
    responses,
    sections,
    useStandardTemplate,
    formData,
    drawOptions,
    currentUser,
    title,
    fileMap,
    formNumber,
    settings,
    logo,
    formSections,
  ]);

  const cleanup = useCallback(() => {
    setResponses({});
    setSubmitTried(false);
    setCustomerIds();
    setProjectIds();
    setBucketIds();
    setVendorId();
    setShowSigModal(false);
    setFieldTriggerMap({});
    onClose();
    setLastDraft();
    setAutosaveEnabled(false);
    setShouldAutosave(false);
    dispatch(clearFormSelection());
  }, [dispatch, onClose]);

  const handleRedirect = useCallback(() => {
    onClose();
  });

  const onSaveDraft = useCallback(async (keepOpen, isAutosave = false) => {
    if (isAutosave) {
      message.info('Autosaving...');
      setIsAutosaving(true);
    }
    const clearedResponses = clearConditionalResponses({ responses, sections });
    const payload = prepareResponsePayload({
      sections,
      responses: clearedResponses,
      title,
      templateId: id,
      assignedFormId,
      assignedDraftId: draftId,
      projectIds,
      bucketIds,
      customerIds,
      vendorId,
      cardId: cardId ?? initialCardId,
    });
    payload.isDraft = true;
    payload.isAutosave = isAutosave;
    payload.sessionDraftId = sessionDraftId;
    if (await dispatch(submitForm(payload, false, { circularFieldIds }))) {
      if (keepOpen) {
        setLastDraft(clearedResponses);
      } else {
        cleanup();
      }
    }
    setIsAutosaving(false);
  }, [
    sections,
    responses,
    title,
    id,
    draftId,
    assignedFormId,
    dispatch,
    cleanup,
    projectIds,
    bucketIds,
    customerIds,
    vendorId,
    cardId,
    sessionDraftId,
    initialCardId,
  ]);

  const dataChanged = useMemo(() => (
    visible
    && !controlledMode
    && !needsApproval
    && responses !== lastDraft
    && formsValuesAreDifferent({
      initialData: initialData || {},
      fileMap,
      sections,
      responses,
    })
  ), [visible, needsApproval, controlledMode, initialData,
    fileMap, sections, responses, lastDraft]);

  const onSaveDraftClicked = useCallback((keepOpen) => {
    CustomConfirmModal({
      title: 'Save a draft?',
      content: (
        <span>
          Click
          {' '}
          <b style={{ fontWeight: 1000, color: 'black' }}>Yes</b>
          {' '}
          to keep a draft of your work.
        </span>
      ),
      okText: 'Yes',
      cancelText: 'No',
      onCancel: keepOpen ? null : cleanup,
      onOk: () => onSaveDraft(keepOpen),
    });
  }, [cleanup, onSaveDraft]);

  const onCloseClicked = useCallback(() => {
    if (!dataChanged || controlledMode || draftDisabled || poCloseMode) return cleanup();
    return onSaveDraftClicked(false);
  }, [controlledMode, dataChanged, onSaveDraftClicked, draftDisabled, cleanup, poCloseMode]);

  const onDraftDelete = useCallback(() => {
    CustomConfirmModal({
      title: 'Clear the draft?',
      content: 'Clearing a draft will delete the draft permanently and disable auto-save functionality',
      okText: 'Clear',
      cancelText: 'No',
      onOk: async () => {
        if (await dispatch(deleteDraft({ id: draftId, formId: assignedFormId }))) {
          setAutosaveEnabled(false);
          if (!assignedFormId && !initialProjectIds?.length) { // Reset manual form back to empty
            setResponses({});
            return;
          }
          const { data = {}, fileMap: selectedFileMap = {} } = selectedFormOverride ?? {};
          if (!data || !selectedFileMap) {
            setResponses({});
            return;
          }
          const originalResponses = preparePreloadedFormTriggerData({
            initialData: data,
            fileMap: selectedFileMap,
            sections,
            templateProjectId: initialProjectIds?.[0],
            templateCustomerId: initialCustomerIds?.[0],
            projectIdMap,
            customerIdMap: customers,
          });
          setResponses(originalResponses);
        }
      },
    });
  }, [
    dispatch,
    draftId,
    assignedFormId,
    selectedFormOverride,
    sections,
    projectIdMap,
    customers,
    initialCustomerIds,
    initialProjectIds,
  ]);

  const tryToSubmitForm = useCallback(async (signatureFile) => {
    const clearedResponses = clearConditionalResponses({ responses, sections });
    const payload = prepareResponsePayload({
      sections,
      responses: clearedResponses,
      title,
      templateId: id,
      assignedFormId,
      assignedDraftId,
      projectIds,
      bucketIds,
      customerIds,
      cardId: cardId ?? initialCardId,
      valueFieldId,
      fieldTriggerMap,
    });

    const finalSubmit = async ({ dataOnly, clearData } = {}) => {
      if (controlledMode || isExternalForm) {
        return controlledSubmit(payload);
      }
      setLoading(true);
      const cfIds = circularFieldIds?.length
        ? circularFieldIds
        : selectedForm?.data?.circularFieldIds;
      const res = await dispatch(submitForm(
        {
          ...payload,
          isOnEditStep,
          isEdit,
          isResubmit,
          signatureFile,
          dataOnly,
          clearData,
          poCloseMode,
        },
        false,
        { circularFieldIds: cfIds },
      ));
      setLoading(false);
      if (res) {
        if (history && !poCloseMode) {
          history.replace?.(redirectUrl);
        }
        cleanup();
      }
      return Promise.resolve();
    };

    const executeSubmit = async () => {
      if (isEdit) {
        await finalSubmit({ dataOnly: true });
      } else if (isResubmit) {
        ResubmitModal(finalSubmit, !!triggeredForms.length);
      } else {
        await finalSubmit({ dataOnly: false });
      }
    };

    const areTimeEntryTablesValid = validateTimeEntryTableResponses({ sections, responses });
    if (!areTimeEntryTablesValid) {
      return CustomConfirmModal({
        title: 'Warning',
        content: 'A time entry exceeds the warning threshold of hours configured for this form. Please review the entries and make sure they are correct.',
        okText: 'Continue',
        cancelText: 'Cancel',
        onOk: async () => {
          await executeSubmit();
        },
      });
    }

    await executeSubmit();
  }, [
    id,
    title,
    sections,
    responses,
    assignedFormId,
    assignedDraftId,
    isOnEditStep,
    isEdit,
    isResubmit,
    controlledMode,
    controlledSubmit,
    projectIds,
    bucketIds,
    customerIds,
    triggeredForms,
    cleanup,
    valueFieldId,
    isExternalForm,
    fieldTriggerMap,
    cardId,
    history,
    initialCardId,
    poCloseMode,
  ]);

  const users = useSelector((state) => state.users.users);
  const positionNames = useSelector((state) => state.settings.positionNames);
  const sectionPermissionMap = useMemo(() => getSectionPermissionMap({
    sections,
    users,
    positionNames,
    userId: Permissions.id,
  }), [sections, users, positionNames]);

  const onSubmitFormClicked = useCallback(async () => {
    setAutosaveEnabled(false);
    if (controlledMode) {
      if (await tryToSubmitForm()) {
        cleanup();
      }
      return;
    }
    setSubmitTried(true);
    const {
      errorMap,
      wentThroughFields,
    } = getErrorsFromResponses({ sections, responses, sectionPermissionMap });
    if (!wentThroughFields || Object.keys(errorMap).length) return;
    if (collectSig) {
      setShowSigModal(true);
      return;
    }
    tryToSubmitForm();
  }, [tryToSubmitForm, collectSig, controlledMode, cleanup, sectionPermissionMap]);

  useEffect(() => {
    const {
      errorMap,
    } = getErrorsFromResponses({ sections, responses, sectionPermissionMap });
    if (submitTried) setErrors(errorMap);
  }, [responses, submitTried, sectionPermissionMap]);

  useEffect(() => {
    if (visible) setCustomerIds(initialCustomerIds?.length ? initialCustomerIds : []);
  }, [visible, initialCustomerIds]);

  useEffect(() => {
    if (visible) setProjectIds(initialProjectIds?.length ? initialProjectIds : [lockedProjectId]);
  }, [visible, initialProjectIds, lockedProjectId]);

  useEffect(() => {
    if (visible) setVendorId(initialVendorIds?.[0]);
  }, [visible, initialVendorIds]);

  useEffect(() => {
    if (visible) setBucketIds(initialBucketIds);
  }, [visible, initialBucketIds]);

  useEffect(() => {
    if (!visible) {
      setResponses({});
      setSubmitTried(false);
      setErrors({});
      setLastDraft();
      setFieldTriggerMap({});
      setAutosaveEnabled(false);
      setShouldAutosave(false);
      setSessionDraftId(uuid());
    } else {
      setAutosaveEnabled(true);
    }
  }, [visible]);

  useEffect(() => {
    const autosaveIntervalId = setInterval(() => {
      setShouldAutosave(true);
    }, AUTOSAVE_INTERVAL);

    return () => {
      setShouldAutosave(false);
      clearInterval(autosaveIntervalId);
    };
  }, []);

  useEffect(() => {
    const autosaveConditions = visible
      && !poCloseMode
      && !draftDisabled
      && !isResubmit
      && shouldAutosave
      && dataChanged
      && autosaveEnabled;

    if (autosaveConditions) {
      onSaveDraft(true, true);
    }
    setShouldAutosave(false);
  }, [
    visible,
    poCloseMode,
    draftDisabled,
    isResubmit,
    shouldAutosave,
    dataChanged,
    autosaveEnabled,
    onSaveDraft,
  ]);

  const divisionList = useMemo(() => [divisionId], [divisionId]);
  const Container = showAsContainer ? React.Fragment : Drawer;

  const lastUpdatedDraftString = lastUpdatedDraft
    ? DateTime.fromMillis(lastUpdatedDraft).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
    : null;

  const submitTitle = useMemo(() => {
    if (isEdit || isOnEditStep) {
      return 'Submit Edit';
    }
    if (isResubmit) {
      return 'Resubmit';
    }
    return 'Submit';
  }, [isEdit, isOnEditStep, isResubmit]);

  const safeInitialCustomerIds = useMemo(() => (
    initialCustomerIds?.length ? initialCustomerIds : []
  ), [initialCustomerIds]);

  const safeInitialProjectIds = useMemo(() => {
    if (initialProjectIds?.length) return initialProjectIds;
    if (lockedProjectId) return [lockedProjectId];
    return [];
  }, [initialProjectIds, lockedProjectId]);

  const safeInitialCostcodeIds = useMemo(() => (
    initialCostcodeIds?.length ? initialCostcodeIds : []
  ), [initialCostcodeIds]);

  const safeInitialVendorIds = useMemo(() => (
    initialVendorIds?.length ? initialVendorIds : []
  ), [initialVendorIds]);

  const safeInitialBucketIds = useMemo(() => (
    initialBucketIds?.length ? initialBucketIds : []
  ), [initialBucketIds]);

  /**
   * HARBOUR-4930
   * Handles race condition with PO/Sub-Contract form responder mode
   * Where template is retrieved after preloading response
   * in FormResponseFields
   */
  const showFormResponseFields = (
    visible && (
      mode === DEFAULT_FORM_RESPONDER_MODE
      || (mode !== DEFAULT_FORM_RESPONDER_MODE && hasLoadedPayableTemplate)
    )
  );

  return (
    <>
      <Container
        push={false}
        title={title}
        width={childSubmitMode ? '55vw' : '90vw'}
        visible={visible}
        maskClosable={false}
        onClose={onCloseClicked}
        bodyStyle={{
          padding: '24px',
        }}
        keyboard={!cardId && !selectedFile}
        placement="right"
        mask={!childSubmitMode}
      >
        {formSelector ?? null}
        { id && (
          <FormResponseFields
            mode={mode}
            errors={errors}
            sections={sections}
            visible={showFormResponseFields}
            responses={responses}
            onResponsesChanged={setResponses}
            onResponseSectionsChanged={setSections}
            initialData={initialData}
            fileMap={fileMap}
            initialCustomerIds={safeInitialCustomerIds}
            initialProjectIds={safeInitialProjectIds}
            initialCostcodeIds={safeInitialCostcodeIds}
            initialVendorIds={safeInitialVendorIds}
            initialBucketIds={safeInitialBucketIds}
            initialCardId={initialCardId}
            initialCardTitle={initialCardTitle}
            templateProjectId={templateProjectId || lockedProjectId}
            templateCustomerId={templateCustomerId}
            selectedFormId={selectedFormId}
            onCustomerIdChanged={setCustomerIds}
            onProjectIdChanged={setProjectIds}
            onVendorIdChanged={setVendorId}
            onBucketIdChanged={setBucketIds}
            style={formSelector ? { position: 'static' } : null}
            divisions={divisionList}
            isExternalForm={isExternalForm}
            header={header}
            templateId={id}
            setFieldTriggerMap={setFieldTriggerMap}
            fieldTriggerMap={fieldTriggerMap}
            sectionPermissionMap={sectionPermissionMap}
            parentForm={parentForm}
            createTimeTableIds={createTimeTableIds}
          />
        )}
        {!poCloseMode && needsApproval ? (
          <DrawerFooter>
            <FormApprovalButton
              formId={assignedFormId}
              type={REJECT_FORM_SUBMISSION}
              redirectToHome={handleRedirect}
              updateData={{
                sections,
                responses,
                title,
                templateId: id,
                assignedFormId,
                assignedDraftId,
                projectIds,
                bucketIds,
                customerIds,
                cardId: cardId ?? initialCardId,
                valueFieldId,
              }}
            />
            <FormApprovalButton
              formId={assignedFormId}
              type={APPROVE_FORM_SUBMISSION}
              redirectToHome={handleRedirect}
              updateData={{
                sections,
                responses,
                title,
                templateId: id,
                assignedFormId,
                assignedDraftId,
                projectIds,
                bucketIds,
                customerIds,
                cardId: cardId ?? initialCardId,
                valueFieldId,
              }}
              sections={sections}
              responses={responses}
            />
          </DrawerFooter>
        ) : (
          <DrawerSubmitFooter
            onClose={hideCancelButton ? null : onCloseClicked}
            onSubmit={onSubmitFormClicked}
            loading={loading || isAutosaving}
            submitTitle={submitTitle}
            submitButtonStyle={submitButtonStyle}
            cancelButtonStyle={cancelButtonStyle}
          />
        )}
        {!previewDisabled && (
          <OnTraccrButton
            icon={<EyeOutlined />}
            title="Preview"
            onClick={onPreviewClicked}
            id="form-response-pdf-preview-button"
          />
        )}
        {draftId && !poCloseMode && (
          <OnTraccrButton
            type="back"
            icon={<UndoOutlined />}
            title="Clear Draft"
            onClick={onDraftDelete}
            id="form-response-draft-clear-button"
            loading={isAutosaving}
          />
        )}
        {draftId && lastUpdatedDraftString && (
          <div className="form-response-draft-timestamp">
            Last saved:
            {' '}
            {lastUpdatedDraftString}
          </div>
        )}
        {dataChanged && !draftDisabled && !poCloseMode && (
          <OnTraccrButton
            type="back"
            icon={<SaveOutlined />}
            title="Save Draft"
            onClick={onSaveDraftClicked}
            id="form-response-draft-save-button"
            style={{
              right: ((isEdit || isOnEditStep) ? 212 : 182)
                + (loading ? 20 : 0)
                + (needsApproval ? 2 : 0)
                + (isResubmit ? 15 : 0)
                + (isAutosaving ? 40 : 0),
              margin: needsApproval ? 2 : 0,
            }}
            loading={isAutosaving}
          />
        )}
        {(!poCloseMode && allowReassignment && (assignedFormId || assignedDraftId)) && (
          <div
            style={{
              position: 'absolute',
              top: '10px',
              right: '100px',
            }}
          >
            <OnTraccrButton title="Reassign Form" onClick={onShowReassignClicked} />
          </div>
        )}
        <FullPhoto
          file={selectedFile}
          rotation={selectedFile?.rotation ?? 0}
          type={selectedFileType}
          onClose={onCloseFile}
          onDownload={onDownload}
          useApryse={!fileIsPreview}
          enableEscapeToClose
        />
        <FormReassignmentDrawer
          visible={showReassignDrawer}
          assignedFormId={assignedFormId}
          assignedDraftId={assignedDraftId}
          formUsers={formUsers}
          onClose={onCloseReassign}
          onCloseForm={onClose}
        />
      </Container>
      <SignatureModal
        canLoadSaved={formData?.collected?.employeeSignature?.editable}
        visible={showSigModal}
        onClose={closeSigModal}
        onSubmit={tryToSubmitForm}
      />
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
FormResponder.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
  redirectUrl: PropTypes.string,
  assignedForm: PropTypes.shape({
    id: PropTypes.string,
    draftId: PropTypes.string,
  }),
  isOnEditStep: PropTypes.bool,
  isEdit: PropTypes.bool,
  isResubmit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  controlledData: PropTypes.shape({
    data: PropTypes.shape({}),
    fileMap: PropTypes.shape({}),
  }).isRequired,
  controlledMode: PropTypes.bool.isRequired, // Used to override dispatching events from here.
  preloadedData: PropTypes.shape({}).isRequired,
  preloadedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  cardId: PropTypes.string.isRequired,
  draftDisabled: PropTypes.bool,
  formSelector: PropTypes.bool, // Allows user to select desired form
  lockedProjectId: PropTypes.string,
  showAsContainer: PropTypes.bool,
  isExternalForm: PropTypes.bool,
  previewDisabled: PropTypes.bool,
  header: PropTypes.node,
  hideCancelButton: PropTypes.bool,
  submitButtonStyle: PropTypes.shape({}),
  cancelButtonStyle: PropTypes.shape({}),
  initialFileMap: PropTypes.object,
  childSubmitMode: PropTypes.bool.isRequired,
  poCloseMode: PropTypes.bool,
  hasLoadedPayableTemplate: PropTypes.bool,
  isCopy: PropTypes.bool,
};

FormResponder.defaultProps = {
  visible: false,
  mode: DEFAULT_FORM_RESPONDER_MODE,
  history: {},
  redirectUrl: '/forms/log',
  assignedForm: {},
  draftDisabled: false,
  showAsContainer: false,
  header: null,
  isExternalForm: false,
  previewDisabled: false,
  isOnEditStep: false,
  isEdit: false,
  isResubmit: false,
  submitButtonStyle: {},
  cancelButtonStyle: {},
  hideCancelButton: false,
  lockedProjectId: undefined,
  formSelector: false,
  initialFileMap: null,
  poCloseMode: false,
  hasLoadedPayableTemplate: false,
  isCopy: false,
};
